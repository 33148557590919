import React from 'react';
import { useParams } from 'react-router-dom';

// imports
import ContentFormWrapper from '../components/Content/ContentFormWrapper';
import AppBreadcrumbs from '../components/Shared/Breadcrumbs';

function MessageStandardizationEditView() {
  const params = useParams();
  const { contentId } = params;

  return (
    <div>
      <AppBreadcrumbs />
      <ContentFormWrapper type="messageStandardizations" id={contentId} edit />
    </div>
  );
}

export default MessageStandardizationEditView;
