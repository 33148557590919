const ImporterStyles = {
  box: {
    borderStyle: 'dashed',
    borderRadius: '25px',
    borderWidth: '2px',
    padding: '20px',
    borderColor: 'grey',
    textAlign: 'center',
    maxWidth: '275px',
    margin: 'auto',
  },
  boxPreview: {
    borderStyle: 'dashed',
    borderRadius: '25px',
    borderWidth: '2px',
    padding: '20px 0',
    borderColor: 'grey',
    textAlign: 'center',
    margin: 'auto',
  },
  thumbnail: {
    width: '50px',
    margin: 'auto',
  },
  button: {
    padding: '5px 30px',
    margin: 'auto',
  },
  file: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'rgba(0,0,0,0.1)',
    borderRadius: 25,
    padding: 10,
  },
};

export default ImporterStyles;
