import React from 'react';
// imports
import VBreadcrumbs from '../components/Shared/Breadcrumbs';
import OutcomeBuilderWrapper from '../components/VirtualConsult/OutcomesBuilder/OutcomeBuilderWrapper';
import QuestionBuilderWrapper from '../components/VirtualConsult/QuestionBuilder/QuestionBuilderWrapper';
import QuestionnaireBuilderWrapper from '../components/VirtualConsult/QuestionnaireBuilder/QuestionnaireBuilderWrapper';
import UserSegmentFormWrapper from '../components/VirtualConsult/UserSegment/UserSegmentFormWrapper';

function VirtualConsultCreateView(props) {
  const { type } = props;
  return (
    <div>
      <VBreadcrumbs />
      {type === 'question' && <QuestionBuilderWrapper />}
      {type === 'outcomes' && <OutcomeBuilderWrapper />}
      {type === 'questionnaire' && <QuestionnaireBuilderWrapper />}
      {type === 'userSegment' && <UserSegmentFormWrapper />}
    </div>
  );
}

export default VirtualConsultCreateView;
