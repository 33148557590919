import React from 'react';
import { useParams } from 'react-router-dom';

// imports
import TenantFormWrapper from '../components/Account/TenantManagement/TenantFormWrapper';
import AppBreadcrumbs from '../components/Shared/Breadcrumbs';

function TenantFormView(props) {
  const { edit } = props;
  const params = useParams();
  const { id } = params;

  return (
    <div>
      <AppBreadcrumbs />
      <TenantFormWrapper edit={edit} id={id} />
    </div>
  );
}

export default TenantFormView;
