const CreateOrderPHRStyles = () => ({
  dialogContainer: {
    '& .MuiPaper-root': {
      borderRadius: 20,
      padding: '0 36px 8px 67px',
      boxSizing: 'border-box',
    },
  },
});

export default CreateOrderPHRStyles;
