import { green } from '@material-ui/core/colors';

const GroupedDraggableItemsWrapperStyles = (theme) => ({
  hoverChild: {
    display: 'none',
  },
  hoverParent: {
    '&:hover': {
      '& $hoverChild': {
        display: 'inline',
        color: 'black',
        opacity: 0.4,
        float: 'right',
        marginRight: 10,
      },
    },
  },
  button: {
    margin: 15,
  },
  buttonArchiveConsent: {
    margin: 7,
  },
  desktopFAQButton: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  mobileFAQButton: {
    display: 'flex',
    marginBottom: '20px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  sticky: {
    position: '-webkit-sticky' /* Safari */,
    // eslint-disable-next-line
    position: 'sticky',
    top: '15%',
  },
  paper: {
    padding: 5,
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  // box: {
  // 	borderStyle: "dashed",
  // 	borderRadius: "25px",
  // 	borderWidth: "2px",
  // 	padding: "20px",
  // 	borderColor: theme.palette.primary.main,
  // 	// textAlign: "center",
  // 	margin: "auto",
  // },
});

export default GroupedDraggableItemsWrapperStyles;
