import React from 'react';

// imports
import RecordRequest from '../components/RecordRequest/RecordRequest';
import AppBreadcrumbs from '../components/Shared/Breadcrumbs';

function RecordRequestDetailView(props) {
  const { type } = props;
  return (
    <div>
      <AppBreadcrumbs />
      <RecordRequest type={type} />
    </div>
  );
}

export default RecordRequestDetailView;
