import React from 'react';

// imports
import Orders from '../components/Orders/Orders';
import AccessControl from '../components/Shared/AccessControl';
import AppBreadcrumbs from '../components/Shared/Breadcrumbs';

import NoAccessView from './NoAccessView';

function OrdersView() {
  return (
    <div>
      <AppBreadcrumbs />
      <AccessControl
        deniedPermissions={['ADMINISTRATOR_RESTRICTED']}
        renderNoAccess={() => <NoAccessView permissionsNeeded="Administrator" />}
      >
        <Orders search />
      </AccessControl>
    </div>
  );
}

export default OrdersView;
