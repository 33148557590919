import React from 'react';
import { useParams } from 'react-router-dom';

// imports
import RapidTestReport from '../components/RapidTest/RapidTestReport';
import AppBreadcrumbs from '../components/Shared/Breadcrumbs';

function RapidTestReportView(props) {
  const { type } = props;
  const params = useParams();
  const { userId } = params;
  return (
    <div>
      <AppBreadcrumbs userId={userId} />
      <RapidTestReport type={type} />
    </div>
  );
}

export default RapidTestReportView;
