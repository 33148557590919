import { green } from '@material-ui/core/colors';

const DragExpansionWrapperStyles = (theme) => ({
  hoverChild: {
    display: 'none',
  },
  hoverParent: {
    '&:hover': {
      '& $hoverChild': {
        display: 'inline',
        color: 'black',
        opacity: 0.4,
        marginLeft: -30,
      },
    },
  },
  button: {
    margin: 15,
  },
  buttonArchiveConsent: {
    margin: 7,
  },
  desktopFAQButton: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  mobileFAQButton: {
    display: 'flex',
    marginBottom: '20px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  sticky: {
    position: 'sticky',
    fallbacks: { position: '-webkit-sticky' },
    // top: "15%",
  },
  paper: {
    padding: 5,
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  toolIcon: {
    '& span.k-tool-icon': {
      height: 'calc( 1.42857em + 16px)!important',
      padding: '8px 8px!important',
    },
  },
  toolInput: {
    '& span.k-input': {
      height: 'calc( 1.42857em + 12px)!important',
      padding: '10px 12px!important',
    },
  },
  toolButton: {
    '& button.k-button': {
      padding: '10px 12px!important',
      height: 'calc( 1.42857em + 16px)!important',
      width: 'calc( 1.42857em + 16px)!important',
    },
  },
  toolDropdown: {
    '& span.k-dropdown': {
      width: '150px!important',
    },
  },
  messageVariablesDropdown: {
    backgroundColor: 'white',
    width: 'calc(100% - 24px)',
    paddingTop: 30,
    overflow: 'auto',
    zIndex: 1,
  },
  messageVariableTitle: {
    position: 'absolute',
    width: 'calc(20% - 12px)',
    marginBottom: 10,
    paddingBottom: 45,
    background: 'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 15%, rgba(255,255,255,1) 50%)',
    zIndex: 2,
  },
  wrapperWithTooltip: {
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    gridGap: 5,
    marginRight: 10,
    '& .MuiFormControlLabel-root': {
      marginRight: 0,
    },
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#505358',
    boxShadow: theme.shadows[1],
    fontSize: 14,
    fontWeight: 400,
    borderRadius: 16,
    padding: 16,
    boxSizing: 'border-box',
    border: '1px solid #E0E0E0',
    maxWidth: 205,
    width: "100%",
    textAlign: "left",
    "&::before": {
      backgroundColor: "white",
      content: '""',
      display: "block",
      position: "absolute",
      width: 12,
      height: 12,
      left: 'calc(50% - 5px)',
      transform: "rotate(45deg)",
      top: -6,
      borderLeft: "1px solid #E0E0E0",
      borderTop: "1px solid #E0E0E0",
    },
  },
  arrow: {
    display: 'none',
  },
});

export default DragExpansionWrapperStyles;
