// styling imports

// material-UI
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import React from 'react';
import { useSelector } from 'react-redux';

import NoAccessViewStyles from '../assets/jss/components/NoAccessViewStyles';
import { selectAuthToken } from '../components/Auth/selectors';
import { selectCurrentTenant } from '../components/Shared/selectors';
import { selectCurrentUserPermissionsLoading } from '../components/Users/selectors';

function NoAccessView(props) {
  const { classes } = props;
  const currentUserPermissionsLoading = useSelector(selectCurrentUserPermissionsLoading);
  const currentTenant = useSelector(selectCurrentTenant);

  const isAuthenticated = useSelector(selectAuthToken);

  if (!currentTenant || currentUserPermissionsLoading || !isAuthenticated) return <div />;

  return (
    <Grid container justifyContent="center" className={classes.noAccess}>
      <Grid item xs={10}>
        <Card variant="outlined" className={classes.card}>
          <WarningRoundedIcon color="secondary" style={{ fontSize: 100 }} />
          <CardContent>
            <Typography variant="h5">We&apos;re sorry... You do not have permission to access this page.</Typography>
            <Typography variant="h5" style={{ marginTop: '25px' }}>
              Please contact your administrator if you believe you received this message in error.
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default withStyles(NoAccessViewStyles)(NoAccessView);
