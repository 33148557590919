import React from 'react';
// imports
import ContentFormWrapper from '../components/Content/ContentFormWrapper';
import AppBreadcrumbs from '../components/Shared/Breadcrumbs';
// import utils from '../components/Shared/Utils/utils';

function MessageStandardizationCreateView() {
  const type = 'messageStandardizations';

  return (
    <div>
      <AppBreadcrumbs />
      <ContentFormWrapper type={type} />
    </div>
  );
}

export default MessageStandardizationCreateView;
