// this will be needed to get a tracer
import opentelemetry from '@opentelemetry/api';
// tracer provider for web
import { CollectorTraceExporter } from '@opentelemetry/exporter-collector';
import { Resource } from '@opentelemetry/resources';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';

// and an exporter with span processor
import { SimpleSpanProcessor } from '@opentelemetry/tracing';
import { WebTracerProvider } from '@opentelemetry/web';

const LIGHTSTEP_TOKEN = process.env.REACT_APP_LIGHTSTEP_TOKEN;
const LIGHTSTEP_URL = process.env.REACT_APP_LIGHTSTEP_URL;
const APP_NAME = 'sf-ui-admin-console-v2';

// Create a provider for activating and tracking spans
const tracerProvider = new WebTracerProvider({
  resource: new Resource({
    [SemanticResourceAttributes.SERVICE_NAME]: APP_NAME,
  }),
});

// Configure a span processor and exporter for the tracer
tracerProvider.addSpanProcessor(
  new SimpleSpanProcessor(
    new CollectorTraceExporter({
      url: LIGHTSTEP_URL,
      serviceName: APP_NAME,
      headers: {
        'Lightstep-Access-Token': LIGHTSTEP_TOKEN,
      },
    })
  )
);

// Register the tracer
tracerProvider.register();

const tracer = opentelemetry.trace.getTracer(APP_NAME);
export default tracer;
