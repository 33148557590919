const MonitoringStyles = (theme) => ({
  iframe: {
    position: 'relative',
    border: 'none',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '90vw',
    height: 2435,
    float: 'right',
  },
  title: {
    fontWeight: 'bold',
  },
  cardContainer: {
    height: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  lastMonthCardTitle: {
    width: '100%',
    color: '#808f9d',
    fontSize: 14,
    fontWeight: 600,
    padding: 10,
    textTransform: 'uppercase',
  },
  drawer: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    bottom: '0%',
    top: '0%',
  },
  drawerOpen: {
    width: '300px',
    marginTop: '60px',
    padding: '20px 15px 70px 15px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '& span': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  drawerClose: {
    marginTop: '60px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
    // width: theme.spacing(7) + 1
  },
  filtersTab: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '50px',
    height: '100%',
    position: 'fixed',
    backgroundColor: 'white',
    top: '0',
    right: '0',
    paddingTop: '80px',
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  filtersSidebarHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  addFilterButton: {
    paddingTop: '10px',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  createdFilterControls: {
    paddingTop: '10px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  loadingMetadataValues: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

export default MonitoringStyles;
