import React from 'react';
// imports
import AppBreadcrumbs from '../components/Shared/Breadcrumbs';
import UserCreateWrapper from '../components/Users/UserCreate';

function UsersView() {
  return (
    <div>
      <AppBreadcrumbs />
      <UserCreateWrapper />
    </div>
  );
}

export default UsersView;
