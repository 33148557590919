import React from 'react';

// imports
import ContactSupport from '../components/ContactSupport/ContactSupport';

function ContactSupportView() {
  return (
    <div>
      <ContactSupport />
    </div>
  );
}

export default ContactSupportView;
