const NavBarStyles = (theme) => ({
  navBar: {
    width: '100vw',
    zIndex: theme.zIndex.drawer + 1,
    left: '0px',
    '& .MuiGrid-container': {
      flexWrap: 'inherit',
    },
  },
  logoWrapper: {
    paddingRight: '0!important',
  },
  logo: {
    height: '50px',
    paddingTop: '5px',
  },
  navigationLinks: {
    paddingRight: '0px',
    marginRight: '-5px',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  link: {
    color: '#ffffff',
    textDecoration: 'none',
    '&:hover': {
      color: '#ffffff',
      opacity: '90%',
    },
  },
  innerLink: {
    color: 'black',
    textDecoration: 'none',
  },
  avatar: {
    marginRight: '15px',
    height: '2.5vw',
    width: '2.5vw',
  },
  arrowIcon: {
    marginLeft: '5px',
  },
  icon: {
    marginRight: '5px',
  },
  mobileDrawer: {
    width: '100%',
    height: '100vh',
    paddingLeft: '4vw',
    paddingRight: '4vw',
    paddingBottom: '25px',
    marginTop: '15px',
  },
  mobileLink: {
    fontWeight: 'lighter',
    width: '100%',
    opacity: '80%',
    marginBottom: '16px',
    textDecoration: 'none',
    color: 'black',
  },
  mobileText: {
    '& span': {
      fontSize: '4.5vw',
      [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
      },
    },
  },
  mobileTenant: {
    backgroundColor: '#ffffff',
    width: '90%',
    marginTop: '5px',
    marginLeft: '10px',
    marginBottom: '5px',
    height: '30px',
    '& li': {
      fontSize: '4vw',
      [theme.breakpoints.up('sm')]: {
        fontSize: '14px',
      },
    },
  },
  nested: {
    paddingLeft: theme.spacing(5),
  },
  divider: {
    backgroundColor: 'black !important',
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  languageSelector: {},
  mobileBtn: {
    width: '60vw',
    borderRadius: 0,
    '& .MuiButton-label': {
      justifyContent: 'left',
      marginLeft: '30px',
    },
  },
  mobileIcon: {
    marginRight: '-15px',
    [theme.breakpoints.up('sm')]: {
      marginRight: '0px',
    },
  },
  formControl: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      minWidth: 120,
      display: 'flex',
    },
  },
  tenantGrid: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      boxSizing: 'initial',
    },
    paddingLeft: '0!important',
  },
  logout: {
    position: 'fixed',
    bottom: '10px',
    fontSize: '4.5vw',
  },
  menuItem: {
    padding: 10,
  },
  rootTenant: {
    fontWeight: 'bold',
  },
  templateTenant: {
    opacity: 0.5,
  },
});

export default NavBarStyles;
