import React from 'react';
import { useParams } from 'react-router-dom';

// imports
import EncounterDetailsWrapper from '../components/EncounterDetails/EncounterDetailsWrapper';
import AppBreadcrumbs from '../components/Shared/Breadcrumbs';

function EncounterDetailsView(props) {
  const { match } = props;

  const params = useParams();
  const { encounterId } = params;

  return (
    <div>
      <AppBreadcrumbs id={encounterId} match={match} />
      <EncounterDetailsWrapper encounterId={encounterId} />
    </div>
  );
}

export default EncounterDetailsView;
