/* eslint-disable no-unused-vars */
const ContentStyles = (theme) => ({
  root: {
    position: 'relative',
  },
  actionLoading: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
  },
  languageWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    '&>div': {
      margin: '0!important',
    },
  },
  languageWrapperMobile: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    '&>div': {
      margin: '0!important',
    },
  },
});

export default ContentStyles;
