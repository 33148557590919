import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// imports
import ContentFormWrapper from '../components/Content/ContentFormWrapper';
import FontEditorWrapper from '../components/Settings/SettingsFonts/FontEditorWrapper';
import AppBreadcrumbs from '../components/Shared/Breadcrumbs';
import { selectCurrentTenant } from '../components/Shared/selectors';
import utils from '../components/Shared/Utils/utils';

function ContentEditView() {
  const params = useParams();
  const type = utils.snakeToCamel(params.assetsType);
  const currentTenant = useSelector(selectCurrentTenant);
  const { contentId } = params;

  return (
    <div>
      <AppBreadcrumbs />
      {type === 'introWidgets' && <ContentFormWrapper type={type} id={contentId} edit />}

      {type === 'fonts' && <FontEditorWrapper currentTenant={currentTenant} fontId={contentId} />}
    </div>
  );
}

export default ContentEditView;
