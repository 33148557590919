import React from 'react';

// imports
import AppAccountPageWrapper from '../components/Account/AccountPageWrapper';
import AppBreadcrumbs from '../components/Shared/Breadcrumbs';

function AccountView(props) {
  const { type } = props;

  return (
    <div>
      <AppBreadcrumbs />
      <AppAccountPageWrapper type={type} />
    </div>
  );
}

export default AccountView;
