import React from 'react';
// component imports
import AppBreadcrumbs from '../components/Shared/Breadcrumbs';
import UserImporter from '../components/Users/UserImporter';

function ImporterView() {
  return (
    <div>
      <AppBreadcrumbs />
      <UserImporter />
    </div>
  );
}

export default ImporterView;
