const ActionCellStyles = (theme) => ({
  actionLoading: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
    backgroundColor: "rgba(255, 255, 255, 0.6)",
  },
});

export default ActionCellStyles;
