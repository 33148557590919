const CreateFormStyles = (theme) => ({
  root: {
    width: '100%',
    '& div.k-dialog': {
      borderRadius: '20px!important',
    },
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  container: {
    width: '92vw',
    [theme.breakpoints.up('md')]: {
      width: '50vw',
      margin: 'auto',
    },
    '& div.MuiGrid-spacing-xs-2': {
      textAlign: 'center',
      justifyContent: 'center',
    },
    '& div#button': {
      margin: '10px auto',
    },
  },
  loadingOverlay: {
    position: 'absolute',
    zIndex: 110,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(255,255,255,0.8)',
    width: '92vw',
    [theme.breakpoints.up('md')]: {
      width: '90vw',
      margin: 'auto',
    },
  },
  dialogWrapper: {
    '& .MuiPaper-root': {
      paddingTop: '0!important',
    },
  },
});

export default CreateFormStyles;
