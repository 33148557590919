import React from 'react';

// imports
import Member from '../components/Member/Member';
import AppBreadcrumbs from '../components/Shared/Breadcrumbs';

function ProfileView(props) {
  const { type } = props;
  return (
    <div>
      <AppBreadcrumbs />
      <Member type={type} />
    </div>
  );
}

export default ProfileView;
