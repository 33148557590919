const TableWrapperStyles = (theme) => ({
  userButtonDesktop: {
    marginRight: 10,
    [theme.breakpoints.down('sm')]: {
      // display: 'none',
      margin: '20px 5px',
    },
  },
  userButton: {
    margin: 20,
  },
  link: {
    textDecoration: 'none',
  },
  customBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  iconButton: {
    margin: 10,
  },
  paper: {
    padding: '15px 30px 15px',
    [theme.breakpoints.down('sm')]: {
      padding: '15px 5px 15px',
    },
  },
  headerItem: {
    margin: '15px 0 15px',
  },
  tableTitle: {
    margin: '15px 0 15px',
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 22,
    color: '#2e5266',
  },
  buttonLoading: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
});

export default TableWrapperStyles;
