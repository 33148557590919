const NotificationStyles = (theme) => ({
  snackBar: {
    padding: '7px 16px 7px 19px',
    borderRadius: '6px',
    width: 'auto',
    zIndex: 20,
  },
  icon: {
    fontSize: 24,
    margin: theme.spacing(1),
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  updateMessage: {
    display: 'flex',
    alignItems: 'center',
  },
  'message-text': {
    display: 'flex',
    alignItems: 'center',
    color: '#FFFFFF',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '24px',
    padding: '0!important',
  },
  'message-error': {
    display: 'flex',
    alignItems: 'center',
  },
  'message-warning': {
    display: 'flex',
    alignItems: 'center',
  },
  'message-info': {
    display: 'flex',
    alignItems: 'center',
  },
  'message-success': {
    display: 'flex',
    alignItems: 'center',
  },
  margin: {
    margin: theme.spacing(1),
  },
  closeIcon: {
    color: '#FFFFFF',
    padding: '0',
  },
  contentAction: {
    paddingLeft: '30px',
  },
  contentMessage: {
    padding: 0,
  },
  root: {
    flexWrap: 'nowrap!important',
  },
  updateNow: {
    '&:hover': {
      color: '#ffffff',
      opacity: '80%',
      cursor: 'pointer',
    },
    textDecoration: 'underline',
    fontWeight: 'bold',
  },
});

export default NotificationStyles;
