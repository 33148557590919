export const paths = {
  start: () => '/',
  dashboard: () => '/:tenant',
  signIn: () => '/signin',
  resetPassword: () => '/resetPassword',
  users: (tenant = ':tenant', userType = ':userType') => `/${tenant}/users/${userType}`,
  usersImport: () => '/:tenant/users/import',
  usersImportStatus: () => '/:tenant/users/import/:id',
  viewAllUploads: (tenant = ':tenant') => `/${tenant}/users/import/view-all-uploads`,
  userCreate: () => '/:tenant/users/create',
  profile: () => '/:tenant/profile/:userId',
  contactSupport: () => '/:tenant/contact/:userId',
  profileAssessment: () => '/:tenant/profile/:userId/assessment',
  profileTesting: () => '/:tenant/profile/:userId/testing',
  profileScans: () => '/:tenant/profile/:userId/scans',
  profileCovidHist: () => '/:tenant/profile/:userId/covidVaccine',
  profileMessages: () => '/:tenant/profile/:userId/messages',
  profileVerification: () => '/:tenant/profile/:userId/verification',
  alerts: () => '/:tenant/alerts',
  encounters: () => '/:tenant/encounters',
  encountersDetails: () => '/:tenant/encounters/details/:encounterId',
  account: () => '/:tenant/account',
  accountDetails: () => '/:tenant/account/details',
  accountTenants: () => '/:tenant/account/tenants',
  accountTenantCreate: () => '/:tenant/account/tenants/create',
  accountPermissions: () => '/:tenant/account/permissions',
  // profileDetails: (tenant = ':tenant', userId = ':userId', userType = ':userType') => `/${tenant}/users/${userType}/details/${userId}`,
  profileDetails: (tenant = ':tenant', userId = ':userId') => `/${tenant}/users/details/${userId}`,
  profileDetailsAssessmentTab: () => '/:tenant/users/details/:userId/assessment',
  profileDetailsRapidTestingReport: (
    tenant = ':tenant',
    userId = ':userId',
    nationalHealthId = ':nationalHealthId',
    encounterId = ':encounterId'
  ) => `/${tenant}/users/details/${userId}/testing/rapid-test/${nationalHealthId}/${encounterId}/${userId}`,
  profileDetailsDiagnosticTestingReport: (
    tenant = ':tenant',
    userId = ':userId',
    nationalHealthId = ':nationalHealthId',
    encounterId = ':encounterId'
  ) => `/${tenant}/users/details/${userId}/testing/diagnostic-report/${nationalHealthId}/${encounterId}/${userId}`,
  profileDetailsTestingTab: (tenant = ':tenant', userId = ':userId') => `/${tenant}/users/details/${userId}/testing`,
  profileDetailsScanTab: () => '/:tenant/users/details/:userId/scans',
  profileDetailsCovidHistTab: () => '/:tenant/users/details/:userId/covidVaccine',
  profileDetailsMessagesTab: () => '/:tenant/users/details/:userId/messages',
  profileVaccineRecordsTab: () => '/:tenant/users/details/:userId/vaccineRecords',
  profileDetailsVerificationTab: () => '/:tenant/users/details/:userId/verification',
  profileDetailsConsentsTab: () => '/:tenant/users/details/:userId/consents',
  profileDetailsDependentAccountsTab: () => '/:tenant/users/details/:userId/dependentAccounts',
  profileDetailsSimulatorTab: () => '/:tenant/users/details/:userId/simulator',
  profileSimulatorTab: () => '/:tenant/profile/:userId/simulator',
  profileDetailsClinicalTab: () => '/:tenant/users/details/:userId/clinical',
  profileDetailsClinicalConditionTab: () => '/:tenant/users/details/:userId/clinical/condition',
  profileDetailsClinicalMedicationTab: () => '/:tenant/users/details/:userId/clinical/medication',
  profileDetailsClinicalPrescriptionTab: () => '/:tenant/users/details/:userId/clinical/prescription',
  profileDetailsClinicalImmunizationTab: () => '/:tenant/users/details/:userId/clinical/immunization',
  profileDetailsClinicalVitalTab: () => '/:tenant/users/details/:userId/clinical/vital',
  profileDetailsClinicalProcedureTab: () => '/:tenant/users/details/:userId/clinical/procedure',
  profileDetailsClinicalCCDTab: () => '/:tenant/users/details/:userId/clinical/ccd',
  profileDetailsClinicalCCDXMLTab: () => '/:tenant/users/details/:userId/clinical/ccd/xml',
  profileDetailsClinicalQuickConnectTab: () => '/:tenant/users/details/:userId/clinical/quickconnect',
  profileDetailsClinicalEMRConnectTab: () => '/:tenant/users/details/:userId/clinical/emrconnect',
  profileDetailsClinicalEprescribeTab: () => '/:tenant/users/details/:userId/clinical/eprescribe',
  profileDetailsClinicalDosespotTab: () => '/:tenant/users/details/:userId/clinical/dosespot',
  orders: () => '/:tenant/diagnostics',
  createOrderPHR: () => '/:tenant/create-order-phr',
  createOrderPHRByEmail: () => '/:tenant/create-order-phr/:userEmail',
  contentEdit: () => '/:tenant/content/:type/details/:contentId',
  contentCreate: () => '/:tenant/content/:type/create',
  contentList: () => '/:tenant/content/',
  contentListByType: () => '/:tenant/content/:type',
  settingsTemplatesTab: () => '/:tenant/settings/system-messages/:tabType',
  settingsEditorTab: () => '/:tenant/settings/system-messages/:tabType/edit/:id',
  settingsMessageStandardizationTab: () => '/:tenant/settings/message-standardization',
  settingsMessageStandardizationEditorTab: () => '/:tenant/settings/message-standardization/details/:contentId',
  settingsMessageStandardizationCreate: () => '/:tenant/settings/message-standardization/create',
  settingsVerbiageTab: () => '/:tenant/settings/verbiage/:tabType',
  settingsNetworkConfigTab: () => '/:tenant/settings/configuration',
  settingsNetworkConfigByTypeTab: () => '/:tenant/settings/configuration/:type',
  settingsNetworkConfigByAssetsTypeTab: (tenant = ':tenant', type = ':type', assetsType = ':assetsType') =>
    `/${tenant}/settings/configuration/${type}/${assetsType}`,
  settingsAssetsEdit: () => '/:tenant/settings/configuration/:type/:assetsType/details/:contentId',
  settingsAssetsCreate: () => '/:tenant/settings/configuration/:type/:assetsType/create',
  medicalCenter: () => '/:tenant/medical-center',
  medicalCenterOrderableTests: () => '/:tenant/medical-center/orderable-tests',
  medicalCenterTestPanels: () => '/:tenant/medical-center/orderable-panels',
  medicalCenterVendors: () => '/:tenant/medical-center/vendors',
  //
  medicalCenterCreateByType: () => '/:tenant/medical-center/:type/create',
  medicalCenterDetails: () => '/:tenant/medical-center/:type/details/:id',
  reports: () => '/:tenant/reports',
  customReport: () => '/:tenant/reports/custom-report',
  diagnosticReport: () => '/:tenant/diagnostics/diagnostic-report/:nationalHealthId/:encounterId/:userId',
  rapidTest: () => '/:tenant/diagnostics/rapid-test/:nationalHealthId/:encounterId/:userId',
  recordRequests: () => '/:tenant/record-requests',
  recordRequestsDetails: () => '/:tenant/record-requests/details/:userEmail/:rrId',
  recordRequestsDetailsClinical: () => '/:tenant/record-requests/details/:userEmail/:rrId/clinical',
  recordRequestsDetailsClinicalCondition: () => '/:tenant/record-requests/details/:userEmail/:rrId/clinical/condition',
  recordRequestsDetailsClinicalMedication: () =>
    '/:tenant/record-requests/details/:userEmail/:rrId/clinical/medication',
  recordRequestsDetailsClinicalPrescription: () =>
    '/:tenant/record-requests/details/:userEmail/:rrId/clinical/prescription',
  recordRequestsDetailsClinicalImmunization: () =>
    '/:tenant/record-requests/details/:userEmail/:rrId/clinical/immunization',
  recordRequestsDetailsClinicalVital: () => '/:tenant/record-requests/details/:userEmail/:rrId/clinical/vital',
  recordRequestsDetailsClinicalProcedure: () => '/:tenant/record-requests/details/:userEmail/:rrId/clinical/procedure',
  recordRequestsDetailsClinicalCCD: () => '/:tenant/record-requests/details/:userEmail/:rrId/clinical/ccd',
  recordRequestsDetailsClinicalQuickConnect: () =>
    '/:tenant/record-requests/details/:userEmail/:rrId/clinical/quickconnect',
  recordRequestsDetailsClinicalEMRConnect: () =>
    '/:tenant/record-requests/details/:userEmail/:rrId/clinical/emrconnect',
  recordRequestsDetailsClinicalEprescribe: () =>
    '/:tenant/record-requests/details/:userEmail/:rrId/clinical/eprescribe',

  // Questionnaires section
  virtualConsult: () => '/:tenant/virtual-consult',
  questionnaires: () => '/:tenant/virtual-consult/questionnaires',
  questionnairesDetails: () => '/:tenant/virtual-consult/questionnaires/details/:tab',
  questionnairesDetailsById: () => '/:tenant/virtual-consult/questionnaires/details/:tab/:id',
  questionnairesCreate: () => '/:tenant/virtual-consult/questionnaires/create',
  questionnairesCreateTab: () => '/:tenant/virtual-consult/questionnaires/create/:tab',
  // question section
  questions: () => '/:tenant/virtual-consult/questions',
  questionsDetailsById: () => '/:tenant/virtual-consult/questions/details/:id',
  questionsCreate: () => '/:tenant/virtual-consult/questions/create',
  // Outcome section
  outcomes: () => '/:tenant/virtual-consult/outcomes',
  outcomesCreate: () => '/:tenant/virtual-consult/outcomes/create',
  outcomesDetailsById: () => '/:tenant/virtual-consult/outcomes/details/:id',
  // User segment section
  userSegments: () => '/:tenant/virtual-consult/user-segments',
  userSegmentsCreate: () => '/:tenant/virtual-consult/user-segments/create',
  userSegmentsDetailsById: () => '/:tenant/virtual-consult/user-segments/details/:id',
  // Monitoring section
  monitoring: () => '/:tenant/virtual-consult/monitoring',
  // Reviewer section
  virtualConsultReviewer: () => '/:tenant/virtual-consult/reviewer',
  virtualConsultReviewerById: () => '/:tenant/virtual-consult/reviewer/:id',

  notFound: () => '*',
};
