const SidebarStyles = (theme) => ({
  drawer: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    bottom: '0%',
    top: '0%',
  },
  drawerOpen: {
    width: '250px',
    height: 'calc(100% - 60px)',
    marginTop: '60px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '& span': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  },
  drawerClose: {
    height: '100%',
    marginTop: '60px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    '& span': {
      display: 'none',
    },
    '& svg': {
      margin: '4px 0px 4px 0px',
      overflowWrap: 'normal',
    },
  },
  toolbar: theme.mixins.toolbar,
  list: {
    height: '100vh',
  },
  link: {
    textDecoration: 'none',
    color: 'black',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  dropdown: {
    '& #content': {
      paddingBottom: '0px',
    },
    '& #settings': {
      paddingBottom: '75px',
    },
  },
  mobileTenant: {
    backgroundColor: '#ffffff',
    width: '90%',
    marginTop: '5px',
    marginLeft: '10px',
    marginBottom: '5px',
    height: '30px',
    '& li': {
      fontSize: '4vw',
      [theme.breakpoints.up('sm')]: {
        fontSize: '14px',
      },
    },
  },
  mobileSidebar: {
    maxWidth: '225px',
    width: '60vw',
    maxHeight: '85%',
  },
  logout: {
    position: 'fixed',
    bottom: '0px',
    fontSize: '4.5vw',
  },
  logoutBtn: {
    width: '60vw',
    borderRadius: 0,
    '& .MuiButton-label': {
      justifyContent: 'left',
      marginLeft: '50px',
    },
  },
  menuButtons: {
    position: 'fixed',
    bottom: '0px',
    fontSize: '4.5vw',
  },
  mobileBtn: {
    width: '60vw',
    borderRadius: 0,
    '& .MuiButton-label': {
      justifyContent: 'left',
      marginLeft: '50px',
    },
  },
  mobileVersion: {
    fontSize: '4.5vw',
    backgroundColor: 'white',
  },
  version: {
    padding: 20,
  },
});

export default SidebarStyles;
