import React from 'react';
import { useParams } from 'react-router-dom';

// imports
import AppContentPageWrapper from '../components/Content/ContentPageWrapper';
import AppBreadcrumbs from '../components/Shared/Breadcrumbs';
import utils from '../components/Shared/Utils/utils';

function ContentListView() {
  const params = useParams();
  const type = utils.snakeToCamel(params.type || '') || 'faq';

  return (
    <div>
      <AppBreadcrumbs />
      <AppContentPageWrapper type={type} />
    </div>
  );
}

export default ContentListView;
