import React from 'react';

// imports
import RecordRequestList from '../components/RecordRequest/RecordRequestList';
import AppBreadcrumbs from '../components/Shared/Breadcrumbs';

function RecordRequestsView() {
  return (
    <div>
      <AppBreadcrumbs />
      <RecordRequestList />
    </div>
  );
}

export default RecordRequestsView;
