const ProfileViewStyles = (theme) => ({
  main: {
    position: 'relative',
    left: '-6vw',
    marginTop: '75px',
  },
  loading: {
    marginTop: '100px',
  },
  desktopBox: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      padding: 10,
      paddingRight: 20,
    },
    '& div#qrCodeModal': {
      border: 'none',
    },
  },
  phoneInputClass: {
    '&:focus': {
      boxShadow: 'none !important',
      '&+div:before': {
        color: '#ffffff !important',
      },
    },
    '&:disabled': {
      cursor: 'default !important',
    },
  },
  mobileBox: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    '& div': {
      padding: '0px',
      margin: 'auto',
      textAlign: 'center',
    },
    '& div#cog': {
      marginTop: '10px',
      textAlign: 'right',
      paddingRight: '15px',
    },
    '& div#uploadPhotoModal': {
      marginTop: '12px',
      marginRight: '4px',
      marginLeft: '8px',
      border: 'none',
    },
    '& div#qrCodeModal': {
      marginRight: '8px',
      marginLeft: '4px',
      border: 'none',
      marginTop: '-10px',
      [theme.breakpoints.up('md')]: {
        marginTop: '0px',
      },
    },
  },
  side: {
    width: '175px',
  },
  name: {
    fontSize: '25px',
    marginTop: '-10px',
  },
  permissionDropdown: {
    marginLeft: '15px',
  },
  infoBox: {
    paddingTop: '0px',
    [theme.breakpoints.up('md')]: {
      marginTop: '5px',
    },
    '& p#nhid': {
      marginBottom: '25px',
    },
  },
  info: {
    fontWeight: 'lighter',
    marginTop: '2px',
    '& svg': {
      verticalAlign: 'bottom',
      marginBottom: '5px',
      marginLeft: '5px',
      fontSize: '20px',
    },
  },
  emailInfo: {
    fontWeight: 'lighter',
    marginTop: '-6px',
    '& svg': {
      marginLeft: '10px',
      fontSize: '16px',
    },
  },
  bottomBox: {
    margin: '55px 0 30px 0',
  },
  activeLink: {
    fontWeight: 'bold',
  },
  infoBoxes: {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    '& .MuiBox-root': {
      padding: '15px',
    },
    '& h4': {
      marginTop: '1px',
      marginBottom: '5px',
      fontSize: '16px',
      fontWeight: 'bold',
    },
    '& p': {
      fontWeight: 'lighter',
      fontSize: '14px',
    },
    '& h5': {
      fontSize: '14px',
      margin: '20px 0 0 0',
    },
  },
  editHover: {
    minWidth: 200,
    borderBottom: 'solid #ececec 1px',
    '&:hover': {
      borderBottom: 'solid 1px',
    },
  },
  noEdit: {
    minWidth: 200,
    borderBottom: 'solid #ececec 1px',
  },
  divider: {
    margin: '15px 0 15px 0',
  },
  addPhotoIcon: {
    '& svg': {
      visibility: 'hidden',
      position: 'absolute',
      bottom: '10px',
      right: '10px',
    },
    '&:hover': {
      '& svg': {
        visibility: 'visible',
      },
    },
    [theme.breakpoints.up('md')]: {
      margin: '-15px 10px 0 10px',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(5),
  },
  modalUpload: {
    visibility: 'hidden',
    position: 'absolute',
    top: '10px',
  },
  closeIcon: {
    overflow: 'auto',
    float: 'right',
    marginTop: '-10px',
    marginRight: '-10px',
  },
  hoverChild: {
    visibility: 'hidden',
  },
  hoverParent: {
    '&:hover': {
      '& $hoverChild': {
        visibility: 'visible',
        color: 'black',
        opacity: 0.4,
      },
    },
  },
  settingsCog: {
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      marginTop: -20,
      marginLeft: 5,
    },
  },
  hidden: {
    visibility: 'hidden',
  },
  nhiBtnInfo: {
    fontWeight: 'lighter',
    marginTop: '2px',
    display: 'flex',
    alignItems: 'center',
  },
  nhiBtnWrapper: {
    position: 'relative',
    marginLeft: 5,
  },
  nhiBtnProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -10,
    marginLeft: -10,
  },
  nhiBtnProgressMobile: {
    position: 'absolute',
    top: '20%',
    left: '40%',
    marginTop: -10,
    marginLeft: -10,
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  basicContainer: {
    width: '49%',
    borderRadius: '20px',
    padding: 16,
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'min-content',
    },
  },
  testImgContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  infoBoxesUpdated: {
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    color: '#505358',
    fontFamily: 'Avenir',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {},
    '& .MuiBox-root': {
      padding: '20px 0',
    },
    '& h4': {
      fontSize: 22,
      fontWeight: 500,
    },
    '& p': {
      fontSize: '16px',
      fontWeight: 500,
    },
    '& h5': {
      fontSize: '16px',
      fontWeight: 700,
    },
  },
  processingMetaDataContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    color: '#505358',
    fontFamily: 'Avenir',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {},
    '& .MuiBox-root': {
      padding: '20px 0',
    },
    '& .MuiGrid-item.MuiGrid-grid-xs-4': {
      minWidth: '100%',
      paddingLeft: '38px'
    },
    '& h4': {
      fontSize: 22,
      fontWeight: 500,
    },
    '& p': {
      fontSize: '16px',
      fontWeight: 500,
    },
    '& h5': {
      fontSize: '16px',
      fontWeight: 700,
    },
  },

  contentWrapper: {
    '& .k-grid-header-wrap>table': {
      width: '100%!important',
    },
    '& .MuiPaper-root': {
      padding: '0 0 15px 0',
    },
    '& .table-button-wrapper': {
      paddingRight: 45,
    },
    '& .k-grid-table': {
      width: '100%!important',
    },
    '& .tableWrapper': {
      padding: 20,
      boxSizing: 'border-box',
    },
  },
  withTableButtons: {
    '& .MuiPaper-root': {
      padding: '15px 0!important',
    },
  },
  inputContainer: {
    width: '100%',
    paddingRight: 50,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
});

export default ProfileViewStyles;
