const VaccineRecordDialogStyles = {
  dialoBodyWrapper: {
    maxHeight: '70vh',
  },
  dialogBox: {
    '&': {
      'scrollbar-color': '#C4C4C4 !important',
      'scrollbar-width': 'thin !important',
    },
    /* Scroll bar Style */
    '& ::-webkit-scrollbar': {
      width: '10px',
    },
    '& ::-webkit-scrollbar-track': {
      background: 'white',
    },
    /* Handle */
    '& ::-webkit-scrollbar-thumb': {
      background: '#C4C4C4',
      borderRadius: '20px',
    },
    /* Handle on hover */
    '& ::-webkit-scrollbar-thumb:hover': {
      background: '#C4C4C4',
      borderRadius: '20px',
    },
    /* ScrollBar Style - End  */

    '& .k-content': {
      overflow: 'hidden',
      height: '100%',
      borderRadius: '5px',
      paddingRight: '0px'
    },
    '& .k-dialog': {
      borderRadius: '20px',
    },
    '& .k-dialog-buttongroup.k-dialog-button-layout-stretched': {
      paddingTop: '16px!important',
      paddingBottom: '16px!important',
    },
    '&.k-dialog-wrapper': {
      // 'z-index': 1,
    },
  },

  previewContainer :{
    margin: 'auto',
    overflowY: 'auto',
    height: '100%'
  },

  previewContainerIcon: {
    position: 'absolute',
    right: '0px',
    backgroundColor: 'rgba(0, 0, 0, .5)',
    color: 'white',
    borderRadius: '3px',
    padding: '3px',
    cursor: 'pointer',
  },

  previewBodyContainer: {
    // minHeight: '350px',
    // maxHeight: '34% !important',
    padding: '0px 10px',
    maxWidth: '460px',
    textAlign: 'center'
  },

  subHeaderText: {
    color: 'rgba(0, 0, 0, 0.54)'
  },

  dialogBodyDetails: {
    minHeight: '350px',
    maxHeight: '500px',
    padding: '10px',
    maxWidth: '390px',
  },

  dialogBodyDetailsOneDose: {
    padding: '10px',
  },

  doseHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  doseHeaderText: {
    fontWeight: '600px',
    fontSize: '20px',
    lineHeight: '30px',
    marginLeft: '-2px',
    color: '#54565B',
  },
  dialogDoseListItem: {
    marginBottom: '26px',
    width: '370px'
  },
  dialogActionBar: {
    '& .k-dialog-buttongroup.k-dialog-button-layout-stretched': {
      paddingTop: '16px!important',
      paddingBottom: '16px!important',
      color: 'blue',
    },
  },
  buttonDelete: {
    '& .MuiIconButton-label': {
      color: '#CB0000',
    },
  },
  buttonApprove: {
    border: '2px solid #00AC11!important',
    borderRadius: '8px!important',
    backgroundColor: '#00AC11!important',
    color: 'white',
    marginLeft: '16px',
    marginTop: '3px',
  },
  buttonRedCancel: {
    border: '2px solid #DF0000!important',
    borderRadius: '8px!important',
    backgroundColor: '#DF0000!important',
    color: 'white',
    marginLeft: '16px',
    marginTop: '3px',
  },
  buttonSave: {
    border: '2px solid #003C71!important',
    borderRadius: '8px!important',
    backgroundColor: '#003C71!important',
    color: 'white',
    paddingLeft: '20px',
    paddingRight: '20px',
    marginLeft: '16px',
    marginTop: '3px',
  },
  buttonOutlined: {
    border: '2px solid #757575!important',
    borderRadius: '8px!important',
    marginLeft: '16px',
    marginTop: '3px',
  },
  actionDialogText: {
    fontSize: '12px',
    paddingRight: '20px',
  },
  inputField: {
    '&.MuiFormControl-root.MuiTextField-root': {
      marginLeft: '3px',
    },
  },
  textInputDisabled: {
    '& .Mui-disabled': {
      color: '#000000',
    },
    '& .MuiInput-underline:before': {
      outline: '0',
      border: '0',
    },
    '& .MuiFormLabel-root': {
      color: '#6E6E6E',
      marginBottom: '4px',
    },
    '&.MuiFormControl-root.MuiTextField-root': {
      marginTop: '12px',
    },
  },
  vaccineSelect: {
    marginTop: '12px',
    marginBottom: '12px',
    overflow: 'hidden',
    '& .MuiPopover-paper': {
      'z-index': '20',
    },
  },
  issuerInput: {
    marginBottom: '12px',
  },
  dateInput: {
    marginTop: '12px',
    marginBottom: '12px',
  },
  selectInput: {
    marginBottom: '12px',
    '& .MuiPaper-root MuiMenu-paper': {
      color: 'blue',
    },
  },

  // NEW CSS
  flexContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    direction: 'row',
    alignItems: 'stretch',
  },
  leftPaneAttestation: {
    flex: '0 0 45%',
    height: '100%',
    overflowY: 'auto',
  },
  rightPaneAttestation: {
    // flex: '0 0 50%',
    width: '100%',
    overflowY: 'auto',
    height: '100%',
    display: 'flow-root',
    alignItems: 'stretch',
    paddingBottom: '10px',
  },
  leftPaneExemption: {
    flex: '0 0 0%',
    height: '100%',
  },
  rightPaneExemption: {
    flex: '1 1 100%',
    height: '100%',
    paddingBottom: '10px',
    overflowY: 'auto'
  },
  formWrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    direction: 'column',
    alignItems: 'stretch',
    paddingRight: '5px',
  },
  imageContainerAttestation: {
    position: 'relative',
    width: '460px',
    height: '33% !important',
    padding: '4px 10px',
    // display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'stretch',
    // height: '100%',
    flexDirection: 'row',
    overflow: 'hidden',
    '& > span.MuiBox-root': {
      display: 'block',
      // justifyContent: 'center',
    },
  },
  vaxImgSpan: {
    borderRadius: '15px !important',
    width: '100%',
    height: '100%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  vaxImg: {
    objectFit: 'contain',
    // width: 'auto',
    // height: 'auto',
    // maxWidth: '100%',
    // maxHeight: '100%',
    width: '100%',
    height: '100%',
    borderRadius: '15px !important',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  noImgContainer: {
    paddingTop: '4px',
    paddingBottom: '4px',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    '& > span.MuiBox-root': {
      display: 'block',
      // justifyContent: 'center',
    },
  },
};

export default VaccineRecordDialogStyles;
