import React from 'react';

// component imports
import AppBreadcrumbs from '../components/Shared/Breadcrumbs';
import AllUploads from '../components/Users/AllUploads';

function AllUploadsView() {
  return (
    <div>
      <AppBreadcrumbs />
      <AllUploads />
    </div>
  );
}

export default AllUploadsView;
