import React from 'react';

// imports
import ContentListWrapper from '../components/Content/ContentListWrapper';
import AppBreadcrumbs from '../components/Shared/Breadcrumbs';
import utils from '../components/Shared/Utils/utils';

function MessageStandardizationView() {
  return (
    <div>
      <AppBreadcrumbs />
      <ContentListWrapper type={utils.snakeToCamel('message-standardizations')} />
    </div>
  );
}

export default MessageStandardizationView;
