import React from 'react';
import { useParams } from 'react-router-dom';

// imports
import MedicalCentersFormWrapper from '../components/MedicalCenters/MedicalCentersFormWrapper';
import AppBreadcrumbs from '../components/Shared/Breadcrumbs';
import utils from '../components/Shared/Utils/utils';

function MedicalCenterFormView(props) {
  const { edit } = props;

  const params = useParams();
  const type = utils.snakeToCamel(params.type);
  const { id } = params;

  return (
    <div>
      <AppBreadcrumbs />
      <MedicalCentersFormWrapper type={type} id={id} edit={edit} />
    </div>
  );
}

export default MedicalCenterFormView;
