import btoa from 'btoa';

import { lsClient } from '../ls-client';
import httpClient from './httpClient';
import tracer from './tracer';

let ajaxRequest = null;

const ZENDESK_DOMAIN = process.env.REACT_APP_ZENDESK_DOMAIN;
const ZENDESK_TOKEN = process.env.REACT_APP_ZENDESK_TOKEN;

const API = {
  async submitZendeskSupport(payload, email) {
    const encodedUserAuth = btoa(`${email}/token:${ZENDESK_TOKEN}`);
    const auth = `Basic ${encodedUserAuth}`;

    return doHttpCall(
      'ZENDESK_POST',
      `https://${ZENDESK_DOMAIN}.zendesk.com/api/v2/requests.json`,
      payload,
      {
        Authorization: auth,
      },
      false
    );
  },

  async getQuestionnaireListRequest(tenantId) {
    const params = {
      page: 0,
      size: 200,
      sort: 'updated,desc',
    };

    return doHttpCall('GET', `/virtual-consult/v1/${tenantId}/questionnaires`, {}, params, true);
  },

  async getOptionListRequest(tenantId, optionType) {
    const url =
      optionType === 'concern'
        ? `/vcb-ehr/v1/review/getconcern/${tenantId}`
        : `/vcb-ehr/v1/review/getreviewer/${tenantId}`;
    const params = {
      page: 0,
      records: 200,
    };
    return doHttpCall('GET', url, {}, params);
  },

  async addOptionRequest(optionType, data) {
    const url = optionType === 'concern' ? '/vcb-ehr/v1/review/addconcern' : '/vcb-ehr/v1/review/addreviewer';
    return doHttpCall('POST', url, data, {});
  },

  async updateOptionRequest(optionType, data) {
    const url = optionType === 'concern' ? '/vcb-ehr/v1/review/updateconcern' : '/vcb-ehr/v1/review/updatereviewer';
    return doHttpCall('POST', url, data, {});
  },

  async deleteOptionRequest(optionType, optionId) {
    const url =
      optionType === 'concern'
        ? `/vcb-ehr/v1/review/removeconcern/${optionId}`
        : `/vcb-ehr/v1/review/removereviewer/${optionId}`;
    return doHttpCall('DELETE', url);
  },

  async getQuestionnaireAnnotationsRequest(questionnaireId) {
    const params = {
      page: 0,
      records: 200,
    };
    return doHttpCall('GET', `/vcb-ehr/v1/review/getannotation/${questionnaireId}`, {}, params);
  },

  async addAnnotationRequest(data) {
    return doHttpCall('POST', '/vcb-ehr/v1/review/addannotation', data, {});
  },

  async updateAnnotationRequest(data) {
    return doHttpCall('POST', '/vcb-ehr/v1/review/updateannotation', data, {});
  },

  async removeAnnotationRequest(annotationId) {
    return doHttpCall('DELETE', `/vcb-ehr/v1/review/removeannotationbyid/${annotationId}`);
  },

  async uploadScreenshotRequest(data) {
    return doHttpCall('POST_SCREENSHOT', '/vcb-ehr/v1/review/uploadscreenshot', data);
  },

  async updateDiscussionData(annotationId, shapeId, discussion) {
    return doHttpCall(
      'POST',
      '/vcb-ehr/v1/review/updateannotationdiscussion',
      { annotationId, shapeId, discussion },
      {}
    );
  },

  async getDashboardDataRequest(tenantId, params, type, section = 'statistic') {
    return doHttpCall('GET', `/admin/v1/${section}/${tenantId}/${type}`, {}, params);
  },

  async getDashboardSystemTotalsRequest(params) {
    return doHttpCall('GET', '/dashboard-reporting/v1/tenantdashboard/get-system-totals', {}, params);
  },

  async getDashboardStatisticsRequest(type, params, startParam, endParam) {
    return doHttpCall('GET', `/dashboard-reporting/v1/tenantdashboard/${type}/${startParam}/${endParam}`, {}, params);
  },

  async getNetworkByTenantIdRequest(tenant) {
    return doHttpCall(
      'GET',
      `resources/v1/content/network/for/tenant/${tenant.id}`,
      {}
      // config,
    );
  },

  async getActualLanguageVersionRequest(tenantId, language) {
    return doHttpCall('GET', `/core/v1/localization/settings/${tenantId}/${language}`, {}, {}, true);
  },

  async getLanguageByVersionRequest(tenantId, language, version) {
    return doHttpCall('GET', `/core/v1/localization/json/${tenantId}/${language}/${version}`, {}, {}, true);
  },

  async loginAdmin(data, params) {
    return doHttpCall('POST', '/identity/v1/users/login', data, params);
  },

  async sendMfaCode(mfaMethod, mfaCode, sessionId, userName, accountId) {
    const endpoint = mfaMethod === 'SMS_MFA' ? 'sms/challenge' : 'totpmfa/RespondToTOTPChallenge';
    return doHttpCall('POST', `/identity/v1/users/${endpoint}`, { mfaCode, sessionId, userName, accountId } );
  },

  async sendresetPassEmail(data) {
    return doHttpCall('POST', '/identity/v1/users/password/reset', data);
  },

  async resetPassword(data) {
    return doHttpCall('POST', '/identity/v1/users/password/reset/confirm', data);
  },

  async getEncounterListRequest(data) {
    return doHttpCall('POST', '/encounter/v1/encounter/search', data, {});
  },

  async getEncounterDataRequest(encounterId) {
    return doHttpCall('GET', `/encounter/v1/encounter/details/${encounterId}`, {}, {});
  },

  async getPatientDataRequest(userId) {
    return doHttpCall('GET', `/encounter/v1/patient/details/${userId}`, {}, {});
  },

  async getProviderDataRequest(providerId) {
    return doHttpCall('GET', `/encounter/v1/provider/details/${providerId}`, {}, {});
  },

  async updateEncounterNotesRequest(encounterId, noteObj) {
    return doHttpCall(
      'POST',
      '/encounter/v1/provider/app/update/encounter/notes',
      { id: encounterId, notes: [noteObj] },
      {}
    );
  },

  async refreshIdToken({ tenantId, refreshToken }) {
    return doHttpCall('POST', 'identity/v1/users/refresh/token', { tenantId, refreshToken }, {});
  },

  async getUsersRequest(params) {
    if (ajaxRequest) {
      ajaxRequest.cancel();
    }
    // [SAF-2546] - creates a new token for upcomming ajax (overwrite the previous one)
    ajaxRequest = httpClient.CancelToken.source();

    return doHttpCall('POST_USERS', '/identity/v1/user/getUsers', {}, params, true, {}, ajaxRequest.token);
  },

  async getInvitedUsersRequest(params) {
    return doHttpCall('GET', '/identity/v1/users/invitations', {}, params);
  },

  async getUserRequest(userId) {
    // return doHttpCall("GET", `/identity/v1/user/getInfo/${userId}`, {}, {});

    const accountId = localStorage.getItem('accountId');
    return doHttpCall('GET', `/identity/v1/users/info/for/${userId}/${accountId}`, {}, {});
  },

  async getUserComplianceStatusRequest(tenantId, healthId) {
    return doHttpCall('GET', `core/v1/member/passport/status/${tenantId}/${healthId}`, {}, {});
  },

  async getUserChildrenRequest(tenant, userId, params) {
    return doHttpCall('GET', `/admin/v1/user/${tenant}/${userId}/child`, {}, params);
  },

  async getCurrentUserRequest() {
    return doHttpCall('GET', '/identity/v1/users/info', {}, {});
  },

  async getMetadataValuesRequest(tenant, metadataKey) {
    return doHttpCall('GET', `/admin/v1/dashboard/${tenant}/metadata-values/${metadataKey}`, {}, {});
  },

  async generateUserNhiRequest(userId) {
    return doHttpCall('PUT', `/admin/v1/user/nationalHealthId?id=${userId}`, {}, {});
  },

  async getUserQRCodeRequest(params) {
    return doHttpCall('GET_PHOTO', '/admin/v1/user/code', {}, params);
  },

  async getUserAccountPermissionRequest() {
    return doHttpCall('GET', '/admin/v1/account/permission', {}, {});
  },

  async deleteUserAccountRequest(userId) {
    return doHttpCall('DELETE', `/identity/v1/users/delete/${userId}`, {}, {});
  },

  async inviteUserRequest(data) {
    return doHttpCall('POST', '/identity/v1/users/invite', data, {});
  },

  async sendEmailInvitationRequest(data) {
    return doHttpCall('POST', '/identity/v1/users/invite/by/email', data, {});
  },

  async resendInvitationRequest(data) {
    return doHttpCall('POST', '/identity/v1/users/reinvite/by/email', data, {});
  },

  async deleteUserTenantRequest(tenantId, userEmail) {
    return doHttpCall('DELETE', `/identity/v1/users/permission/${tenantId}/${userEmail}`);
  },

  async getUserPermissionsRequest(tenant, userId) {
    return doHttpCall('GET', `/identity/v1/users/${tenant}/permission/${userId}`, {}, {});
  },

  async updateUserPermissionsRequest(tenant, permission, params) {
    return doHttpCall('POST', `/admin/v1/user/${tenant}/${permission}`, {}, params);
  },

  async toggleAnalyticsFieldRequest(tenant, userId) {
    return doHttpCall('PUT', `/admin/v1/user/${tenant}/permission/analytics?id=${userId}`, {}, {});
  },

  async getUserPicRequest(params) {
    return doHttpCall('GET_PHOTO', '/admin/v1/user/pic', {}, params);
  },

  async getOtherUserPicRequest(UUID) {
    return doHttpCall('GET', `/core/v1/member/getanyuserpic?userUUID=${UUID}`, {}, {});
  },

  async uploadUserPicRequest(payload) {
    return doHttpCall('POST_PHOTO', '/admin/v1/user/pic', payload, {});
  },

  async addUserRequest(data) {
    return doHttpCall('POST', '/identity/v1/users/add', data, {});
  },

  async updateUserRequest(data) {
    return doHttpCall('PUT', '/identity/v1/users/update/profile', data, {});
  },

  async updateUserContactRequest(data) {
    return doHttpCall('PUT', '/identity/v1/users/update/contact', data, {});
  },

  async updateUserStatusRequest(tenant, email, accountId) {
    return doHttpCall('POST', `/identity/v1/users/updateuserstatusbytenantid/${email}/${accountId}/${tenant}`, {}, {});
  },
  async getUserMessagesRequest(tenant, params) {
    return doHttpCall('GET', `/admin/v1/user/message/${tenant}`, {}, params);
  },

  async bulkImportRequest(data) {
    return doHttpCall('POST_FILE', '/identity/v1/importusers', data, {});
  },

  async getBulkInfoRequest(tenant, id) {
    return doHttpCall('GET', `/admin/v1/user/${tenant.id}/bulk/${id}`, {}, {});
  },

  async getUserBulksRequest(tenant, params) {
    return doHttpCall('GET', `/admin/v1/user/${tenant.id}/bulk`, {}, params);
  },

  async getQuestionnaireExecutionRequest(tenant, params) {
    return doHttpCall('GET', `/admin/v1/user/questionnaire-execution/${tenant}`, {}, params);
  },

  async getUserIdentityVerificationHistoryRequest(tenant, userId) {
    return doHttpCall(
      'GET',
      `/identity-verification/v1/identityinformation/getallbyuserandtenant/${tenant}/${userId}`,
      {},
      {}
    );
  },

  async getAccountDetailsRequest() {
    return doHttpCall('GET', '/admin/v1/account', {}, {});
  },

  async updateAccountRequest(data) {
    return doHttpCall('PUT', '/admin/v1/account', data, {});
  },

  async getPermissionListRequest(params) {
    return doHttpCall('GET', '/admin/v1/account/permissions', {}, params);
  },

  async setPermissionRequest(userId) {
    return doHttpCall('PUT', '/admin/v1/account/permission', {}, { uId: userId });
  },

  async removePermissionRequest(userId) {
    return doHttpCall('DELETE', '/admin/v1/account/permission', {
      uId: userId,
    });
  },

  async getTenantsRequest(params) {
    return doHttpCall('GET', '/admin/v1/tenant', {}, params);
  },

  async getTenantRequest(id) {
    return doHttpCall('GET', `/admin/v1/tenant/${id}`, {}, {});
  },

  async getChannelsRequest() {
    return doHttpCall('GET', '/resources/v1/content/ChannelPartner', {}, {});
  },

  async addTenantRequest(data) {
    return doHttpCall('POST', '/identity/v1/tenants/create', data, {});
  },

  async getAlertsRequest(tenant, params) {
    return doHttpCall('GET', `/admin/v1/alert/${tenant}`, {}, params);
  },

  async readAllAlertsRequest(tenant) {
    return doHttpCall('POST', `/admin/v1/alert/${tenant}/read`, {}, {});
  },

  async getContentListRequest(type, params) {
    return doHttpCall('GET', `/resources/v1/content/${type == 'videos' ? 'allvideos' : type}`, {}, params);
  },

  async getContentRequest(type, id, params) {
    return doHttpCall('GET', `/resources/v1/content/${type.substring(0, type.length - 1)}/${id}`, {}, params);
  },

  async getMessageContentRequest(type, id) {
    return doHttpCall('GET', `/resources/v1/content/messagestandardization/${id}`, {}, {});
  },

  async getMessageStandardizationRequest(data) {
    return doHttpCall('POST', '/resources/v1/content/messagestandardization/list', data, {});
  },

  async getWidgetTypesRequest() {
    return doHttpCall('GET', '/resources/v1/content/introwidget/widgettypes', {}, {});
  },

  async getWidgetKeysRequest() {
    return doHttpCall('GET', '/resources/v1/content/introwidget/widgetkeys', {}, {});
  },

  async addContentRequest(data, type) {
    return doHttpCall('POST', `/resources/v1/content/create/${type.substring(0, type.length - 1)}`, data, {});
  },

  async addMessageContentRequest(data, type) {
    return doHttpCall(
      'POST',
      `/resources/v1/content/${type.substring(0, type.length - 1).toLowerCase()}/create`,
      data,
      {}
    );
  },

  async updateContentRequest(data, type) {
    return doHttpCall('PUT', `/resources/v1/content/update/${type.substring(0, type.length - 1)}`, data, {});
  },

  async updateMessageContentRequest(data, type) {
    return doHttpCall(
      'PUT',
      `/resources/v1/content/${type.substring(0, type.length - 1).toLowerCase()}/update`,
      data,
      {}
    );
  },

  async deleteContentRequest(type, id) {
    return doHttpCall('DELETE', `/resources/v1/content/delete/${type.substring(0, type.length - 1)}/${id}`, {}, {});
  },

  async deleteMessageRequest(type, id) {
    return doHttpCall('DELETE', `resources/v1/content/${type.substring(0, type.length - 1)}/delete/${id}`, {}, {});
  },

  async getVideosRequest(params) {
    return doHttpCall('GET', '/resources/v1/content/allVideos', {}, params);
  },

  async updateVideoRequest(payload) {
    return doHttpCall('PUT', '/resources/v1/content/updateAll/video', payload, {});
  },

  async deleteVideoRequest(video) {
    return doHttpCall('DELETE', `resources/v1/content/delete/video/${video._id}`, {}, {});
  },

  async getLabTestOrderableIdsRequest(tenant, onlyFacility) {
    return doHttpCall(
      'POST',
      `/diagnostic-builder/v1/labtestorderabletenant/getorderabletests/${tenant}`,
      {
        returnOnlyTestKits: false,
        returnOnlyFacilityTest: !!onlyFacility,
      },
      {}
    );
  },

  async getLabTestPanelIdsRequest(tenant) {
    return doHttpCall(
      'POST',
      `/diagnostic-builder/v1/labtestpaneltenant/gettestpanels/${tenant}`,
      {
        returnOnlyTestKits: false,
        returnOnlyFacilityTest: false,
      },
      {}
    );
  },

  async createPHROrderRequest(tenant, data) {
    return doHttpCall('POST', `/lab-order/v1/order/createorderforuserconsole/${tenant}`, data, {});
  },

  async getOrderDetailsRequest(encounterId) {
    return doHttpCall('GET', `/lab-order/v1/order/getorderdetailsbyorderid/${encounterId}`, {}, {});
  },

  async getVendorDetailRequest(endpoint) {
    return doHttpCall('GET', `/diagnostic-builder/v1/${endpoint}`, {}, {});
  },

  async getLabTestsRequest(tenant, username, params) {
    return doHttpCall('GET', `/lab-order/v1/order/getordersbyuserforadminconsole/${tenant}/${username}`, {}, params);
  },

  async getTenantLabTestsRequest(tenant, data) {
    return doHttpCall('POST', `/lab-order/v1/order/ordersearch/${tenant}`, data);
  },

  async getDiagnosticsFiltersRequest(type) {
    return doHttpCall('GET', `/diagnostic-builder/v1/${type}`, {}, {});
  },

  async createBulkLabOrderRequest(tenant, source, params, data) {
    return doHttpCall('POST', `/admin/v1/laboratory-test/bulk-create/${tenant}/${source}`, data, params);
  },

  async createLabOrderRequest(tenant, data) {
    return doHttpCall('POST', `/lab-order/v1/order/createorderwithresultconsole/${tenant}`, data, {});
  },

  async renewLabTestRequest(testId) {
    return doHttpCall('POST', `/admin/v1/laboratory-test/${testId}/renew`, {});
  },

  async downloadResultsRequest(testId) {
    return doHttpCall('GET_PDF', `/admin/v1/laboratory-test/${testId}/results`, {}, {});
  },

  async downloadRequisitionRequest(testId) {
    return doHttpCall('GET_PDF', `/admin/v1/laboratory-test/${testId}/requisition`, {}, {});
  },

  async downloadLabelRequest(testId) {
    return doHttpCall('GET_PDF', `/admin/v1/laboratory-test/${testId}/label-data`, {}, {});
  },

  async findProviderRequest(params) {
    return doHttpCall('GET', '/core/v1/provider/admin/providers/directory', {}, params);
  },

  async getUserScanHistoryRequest(tenant, params) {
    return doHttpCall('GET', `/admin/v1/laboratory-test/byUser/${tenant}/scan`, {}, params);
  },

  async getDiagnosticReportRequest(safeEMRId, encounterId, tenant) {
    return doHttpCall('GET', `/lab-order/v1/result/getresult/${tenant}/${encounterId}`, {}, {});
  },

  async updateDiagnosticReportRequest(tenant, data) {
    return doHttpCall('POST', `/lab-order/v1/result/save/${tenant}`, data, {});
  },
  async getPublicHealthReportRequest(encounterId) {
    return doHttpCall(
      'GET',
      `/data-reporting/v1/publichealthreporting/getpublichealthrecordgroupeddata/${encounterId}`,
      {},
      {}
    );
  },

  async deleteMetaDataKeyRequest(tenant, metaDataKey) {
    return doHttpCall('DELETE', `/admin/v1/metadata/${tenant}/byKey/${metaDataKey}`, {}, {});
  },

  async getMetaDataListRequest(tenant, params) {
    return doHttpCall('GET', `/admin/v1/metadata/${tenant}`, {}, params);
  },

  async updateNetworkRequest(tenant, data) {
    return doHttpCall('PUT', `/resources/v1/content/update/network/${tenant}`, data, {});
  },

  async getCheckerScreenParamRequest(tenant) {
    return doHttpCall('GET', `/admin/v1/metadata/${tenant}/checkerScreen`, {}, {});
  },

  async updateMetaDataKeyRequest(tenant, editingKeyword, newMetaDataKey) {
    return doHttpCall('PUT', `/admin/v1/metadata/${tenant}/byKey/${editingKeyword}/raname/${newMetaDataKey}`, {}, {});
  },

  async addMetaDataKeyRequest(tenant, newMetaDataKey) {
    return doHttpCall('POST', `/admin/v1/metadata/${tenant}/byKey/${newMetaDataKey}`, {}, {});
  },

  async updateMetaDataKeyEnablingRequest(tenant, selectedKeyword) {
    return doHttpCall('PUT', `/admin/v1/metadata/${tenant}/byKey/${selectedKeyword}/enable/true`, {}, {});
  },

  async enableDisplayOnCheckerScreenRequest(tenant, enableVal) {
    return doHttpCall('PUT', `/admin/v1/metadata/${tenant}/checkerScreen/${enableVal}`, {}, {});
  },

  async searchProviderRequest(providerData) {
    return doHttpCall('POST', '/provider-directory/v1/providerorganization/search', providerData, {});
  },

  async getAllRecordRequestsRequest(tenant, params) {
    return doHttpCall('GET', `/recordrequest/v1/RecordRequest/list/${tenant}`, {}, params);
  },

  async getRecordRequestRequest(tenant, recordRequestId) {
    return doHttpCall('GET', `/recordrequest/v1/RecordRequest/${tenant}/${recordRequestId}`, {}, {});
  },

  async getCCDRequest(tenant, userId, encounterId) {
    return doHttpCall('GET', `/sequoia/v1/getccd/${tenant}/${userId}/${encounterId}`, {}, {});
  },

  async getDataFromSpecificFacilityRequest(tenant, userId, facilityId) {
    return doHttpCall('POST', `/sequoia/v1/getdatafromspecificfacility/${tenant}/${userId}/${facilityId}`, {}, {});
  },

  async getDataFromAllFacilitiesInTheStateRequest(tenant, userId, state) {
    return doHttpCall('POST', `/sequoia/v1/getdatafromallfacilitiesinthestate/${tenant}/${userId}/${state}`, {}, {});
  },

  async getSequoiaDataFromWithinRangeRequest(tenant, data) {
    return doHttpCall('POST', `/sequoia/v1/getsequoiadatafromallfacilieswithinrange/${tenant}`, data, {});
  },

  async getSequoiaDataFromAllFaciliesRequest(tenant, userId) {
    return doHttpCall('POST', `/sequoia/v1/getsequoiadatafromallfacilies/${tenant}/${userId}`, {}, {});
  },

  async retrivePatientDemographisRequest(patientData, tenantId, safeEMRId) {
    return doHttpCall(
      'POST',
      `/synchealthrecord/v1/retrievepatientdemographicsfromadminconsole/${tenantId}/${safeEMRId}`,
      patientData,
      {}
    );
  },

  async getOneupSyncStatusRequest(tenantId, oneupSyncStatusId) {
    return doHttpCall(
      'GET',
      `/synchealthrecord/v1/getoneupstatus/${tenantId}?oneupStatusId=${oneupSyncStatusId}`,
      {},
      {}
    );
  },

  async validateAccountIdRequest(accountId) {
    return doHttpCall('GET', `/identity/v1/accounts/valid/${accountId}`, {}, {}, false);
  },

  async getMyTenantsRequest() {
    return doHttpCall('GET', '/admin/v1/tenant/my', {}, {});
  },

  async getRapidTestRequest(encounterId) {
    return doHttpCall('GET', `/resources/v1/content/rapidtest/${encounterId}`, {}, {});
  },

  async getRapidTestImageRequest(imageId) {
    return doHttpCall('GET', `/resources/v1/content/rapidtest/image/${imageId}`, {}, {});
  },

  async getRapidTestImageMembraneRequest(membraneId) {
    return doHttpCall('GET', `/resources/v1/content/rapidtest/image/membrane/${membraneId}`, {}, {});
  },

  async getRapidTestImageKitRequest(kitId) {
    return doHttpCall('GET', `/resources/v1/content/rapidtest/image/kit/${kitId}`, {}, {});
  },

  async getAllergyListRequest(safeEMRId) {
    return doHttpCall('GET', `/patient-record/v1/Allergy/list/${safeEMRId}`, {}, {});
  },

  async postAllergyListRequest(safeEMRId, data) {
    return doHttpCall('POST', '/patient-record/v1/Allergy/managedetails', data, {});
  },

  async postAllergyToDrfirstRequest(safeEMRId) {
    return doHttpCall('POST', `/drfirst/v1/Allergy/sendallergy?id=${safeEMRId}`, {}, {});
  },

  async getConditionListRequest(safeEMRId) {
    return doHttpCall('GET', `/patient-record/v1/Condition/list/${safeEMRId}`, {}, {});
  },

  async postConditionListRequest(data) {
    return doHttpCall('POST', '/patient-record/v1/Condition/managedetails', data, {});
  },

  async postConditionToDrfirstRequest(safeEMRId) {
    return doHttpCall('POST', `/drfirst/v1/Problem/sendproblem?id=${safeEMRId}`, {}, {});
  },

  async getMedicationListRequest(safeEMRId) {
    return doHttpCall('GET', `/patient-record/v1/Medication/list/${safeEMRId}`, {}, {});
  },

  async postMedicationListRequest(safeEMRId, data) {
    return doHttpCall('POST', '/patient-record/v1/Medication/managedetails', data, {});
  },

  async postMedicationToDrfirstRequest(safeEMRId) {
    return doHttpCall('POST', `/drfirst/v1/Medication/sendmedication?id=${safeEMRId}`, {}, {});
  },

  async getImmunizationListRequest(safeEMRId) {
    return doHttpCall(
      'POST',
      `/patient-record/v1/Vaccination/getlist/${safeEMRId}`,
      { vG_NAME: 'All' }, // hardcoded to get all categories of vaccine
      {}
    );
  },

  async postImmunizationListRequest(safeEMRId, data) {
    return doHttpCall('POST', '/patient-record/v1/Vaccination/managevaccinationdetails', data, {});
  },

  async getPrescriptionListRequest(safeEMRId) {
    return doHttpCall('GET', `/patient-record/v1/Prescription/list/${safeEMRId}`, {}, {});
  },

  async pullImmunizationRequest(tenant, safeEMRId) {
    return doHttpCall('GET', `/immunizationintegration/v1/pullimmunization/${tenant}/${safeEMRId}`, {}, {});
  },

  async pushImmunizationRequest(tenant, safeEMRId) {
    return doHttpCall('POST', `/immunizationintegration/v1/pushimmunization/${tenant}`, { safeEMRId }, {});
  },

  async getVitalListRequest(safeEMRId) {
    return doHttpCall('GET', `/patient-record/v1/Vital/list/${safeEMRId}`, {}, {});
  },

  async getProcedureListRequest(safeEMRId) {
    return doHttpCall('GET', `/patient-record/v1/Procedure/list/${safeEMRId}`, {}, {});
  },

  async getCCDViewerRequest(safeEMRId) {
    return doHttpCall('GET', `/patient-record/v1/Patient/emrid/getCCDJson/${safeEMRId}`, {}, {});
  },

  async getXMLViewerRequest(safeEMRId, tenantId) {
    return doHttpCall('GET', `/ehr/v1/v2/Patient/getccdforpatient/${tenantId}/${safeEMRId}`, {}, {});
  },

  async postPatientRequest(data) {
    return doHttpCall('POST', '/drfirst/v1/Patient/sendpatient', data, {});
  },

  async getEpicAuthUrlRequest(safeEMRId, tenantId) {
    return doHttpCall('GET', `/synchealthrecord/v1/epic/constructauthurl/${tenantId}?safeEMRId=${safeEMRId}`, {}, {});
  },

  async getCernerAuthUrlRequest(safeEMRId, tenantId) {
    return doHttpCall('GET', `/synchealthrecord/v1/cerner/constructauthurl/${tenantId}?safeEMRId=${safeEMRId}`, {}, {});
  },

  async getAccessTokenRequest(safeEMRId, tenantId) {
    return doHttpCall('GET', `/synchealthrecord/v1/getaccesstokenfromadminconsole/${tenantId}/${safeEMRId}`, {}, {});
  },

  async getLabTestsVendorsRequest(endpoint) {
    return doHttpCall('GET', `/diagnostic-builder/v1/${endpoint}`, {}, {});
  },

  async getPaginatedLabTestsMasterRequest(endpoint, payload) {
    return doHttpCall('POST', `/diagnostic-builder/v1/${endpoint}/Search`, payload, {});
  },

  async getPaginatedLabTestsRequest(tenant, type) {
    let url = '';
    if (type === 'testOrderable') {
      url = `/diagnostic-builder/v1/labtestorderabletenant/getorderabletests/${tenant}`;
    } else if (type === 'testPanels') {
      url = `/diagnostic-builder/v1/labtestpaneltenant/gettestpanels/${tenant}`;
    }
    return doHttpCall(
      'POST',
      url,
      {
        returnOnlyTestKits: false,
        returnOnlyFacilityTest: false,
      },
      {}
    );
  },

  async saveTenantLabTestsRequest(endpoint, tenant, data) {
    return doHttpCall('POST', `/diagnostic-builder/v1/${endpoint}Tenant/addupdate/${tenant}`, data, {});
  },

  async getLabTestRequest(endpoint, tenant, id) {
    return doHttpCall('GET', `/diagnostic-builder/v1/${endpoint}/${tenant.id}/${id}`, {}, {});
  },

  async newLabTestRequest(endpoint, tenant, data) {
    return doHttpCall('POST', `/diagnostic-builder/v1/${endpoint}/${tenant.id}`, data, {});
  },

  async updateLabTestRequest(endpoint, tenant, data) {
    return doHttpCall('PUT', `/diagnostic-builder/v1/${endpoint}/${tenant.id}`, data, { tenantid: tenant.id });
  },

  async getVendorsListRequest() {
    return doHttpCall('GET', '/diagnostic-builder/v1/vendordetail', {}, {});
  },

  async getReportsRequest(tenant, params) {
    return doHttpCall('GET', `/admin-datareporting/v1/reports/${tenant}`, {}, params);
  },

  async downloadReportRequest(tenant, reportId) {
    return doHttpCall('GET_PDF', `/admin-datareporting/v1/reports/${tenant}/${reportId}/dl`, {}, {});
  },

  async getReportPreviewRequest(tenant, reportId) {
    return doHttpCall('GET_ARRAYBUFFER', `/admin-datareporting/v1/reports/${tenant}/${reportId}/dl`, {}, {});
  },

  async createReportRequest(tenant, params) {
    return doHttpCall('POST', `/admin-datareporting/v1/reports/${tenant}`, {}, params);
  },

  async getCustomReportRequest(tenant, data) {
    return doHttpCall('POST', `/admin-datareporting/v1/reports/${tenant}/dl`, data, {});
  },

  async getTenantSettingsRequest(tenant) {
    return doHttpCall('GET', `/admin/v1/tenant/${tenant}/config`, {}, {});
  },

  async updateTenantSettingsRequest(tenant, config) {
    return doHttpCall('PUT', `/admin/v1/tenant/${tenant}/config`, config, {});
  },

  async getAllTemplatesRequest(account, tenant, language, params) {
    return doHttpCall('GET', `/admin/v1/comm-template-definition/${account}/${tenant}/${language}`, {}, params);
  },

  async getDefaultMenuItemsRequest() {
    return doHttpCall('GET', '/admin/v1/tenant/menu-items', {}, {});
  },

  async getTemplateRequest(account, tenant, id) {
    return doHttpCall('GET', `/admin/v1/comm-template/${account}/${tenant}/${id}`, {}, {});
  },

  async getNewTemplateRequest(account, tenant) {
    return doHttpCall('GET', `/admin/v1/comm-template/${account}/${tenant}/template`, {}, {});
  },

  async getTemplateSettingsRequest(account, tenant, id) {
    return doHttpCall('GET', `/admin/v1/comm-template-setting/${account}/${tenant}/${id}/settings`, {}, {});
  },

  async saveTemplateRequest(account, tenant, templateId, tpl) {
    return doHttpCall('POST', `/admin/v1/comm-template/${account}/${tenant}/${templateId}`, tpl, {});
  },

  async updateTemplateRequest(account, tenant, templateId, tpl) {
    return doHttpCall('PUT', `/admin/v1/comm-template/${account}/${tenant}/${templateId}`, tpl, {});
  },

  async testTemplateRequest(account, tenant, templateId, params) {
    return doHttpCall('POST', `/admin/v1/comm-template/${account}/${tenant}/${templateId}/test`, {}, params);
  },

  async saveTemplateSettingsRequest(account, tenant, id, settings) {
    return doHttpCall('PUT', `/admin/v1/comm-template-setting/${account}/${tenant}/${id}/settings`, settings, {});
  },

  async createTemplateSettingsRequest(account, tenant, id, language, settings) {
    return doHttpCall('POST', `/admin/v1/comm-template-setting/${account}/${tenant}/${id}/settings`, settings, {});
  },

  async generateTemplateRequest(account, tenant, id, data) {
    return doHttpCall('POST', `/admin/v1/comm-template/${account}/${tenant}/${id}`, data, {});
  },

  async createTemplateDef(account, tenant, definition) {
    return doHttpCall('POST', `/admin/v1/comm-template-definition/${account}/${tenant}`, definition, {});
  },

  async transformFaqsRequest(tenant) {
    return doHttpCall('POST', `/resources/v1/content/faq/${tenant}`, {}, {});
  },

  async updateFaqRequest(faq) {
    return doHttpCall('PUT', '/resources/v1/content/faq', faq, {});
  },

  async getFaqsRequest(params) {
    return doHttpCall('GET', 'resources/v1/content/faqs/type', {}, params);
  },

  async addFaqRequest(faq) {
    return doHttpCall('POST', '/resources/v1/content/faq', faq, {});
  },

  async importFaqRequest(faq) {
    return doHttpCall('POST', '/resources/v1/content/faq/import', faq, {});
  },

  async removeFaqRequest(faqId) {
    return doHttpCall('DELETE', `/resources/v1/content/faq/${faqId}`);
  },

  async getVerbiageRequest(account, tenant, language) {
    return doHttpCall('GET', `/admin/v1/verbiagedetail/${account}/${tenant}/${language}`, {}, {});
  },

  async getVerbiageDefinitionsRequest(account, tenant, language) {
    return doHttpCall('GET', `/admin/v1/verbiagedefinition/${account}/${tenant}/${language}`, {}, {});
  },

  async updateVerbiageRequest(account, tenant, language, verbiage) {
    return doHttpCall('PUT', `/admin/v1/verbiagedetail/${account}/${tenant}/${language}`, verbiage, {});
  },

  async createVerbiageDefRequest(account, tenant, verbiage) {
    return doHttpCall('POST', `/admin/v1/verbiagedefinition/${account}/${tenant}`, verbiage, {});
  },

  async deleteVerbiageRequest(account, tenant, language, verbiageKey) {
    return doHttpCall(
      'DELETE',
      `/admin/v1/verbiagedetail/${account}/${tenant}/byKey/${verbiageKey}/${language}`,
      {},
      {}
    );
  },

  async createVerbiageDetRequest(account, tenant, verbiage) {
    return doHttpCall('POST', `/admin/v1/verbiagedetail/${account}/${tenant}`, verbiage, {});
  },

  async archiveVerbiageConsentRequest(tenant, params) {
    return doHttpCall('POST', `/admin/v1/user/consent/${tenant}/archive`, {}, params);
  },

  async previewVerbiageRequest(tenant, verbiage) {
    return doHttpCall('POST', `/admin/v1/verbiagedetail/${tenant}/preview`, verbiage, {});
  },

  async updateRootTemplateTenantRequest(tenant, type) {
    return doHttpCall('PUT', `/admin/v1/tenant/${tenant}/${type}`, {}, {});
  },

  async getAllFontsRequest(tenant) {
    return doHttpCall('GET', `/admin/v1/preferences/${tenant}/fonts`, {}, {});
  },

  async addNewFontRequest(tenant, data) {
    return doHttpCall('POST', `/admin/v1/preferences/${tenant}/fonts/byId`, data, {});
  },

  async getFontDetailsRequest(tenant, fontId) {
    return doHttpCall('GET', `/admin/v1/preferences/${tenant}/fonts/byId/${fontId}`, {}, {});
  },

  async updateFontDataRequest(tenant, fontId, data) {
    return doHttpCall('POST', `/admin/v1/preferences/${tenant}/fonts/byId/${fontId}`, data, {});
  },

  async deleteFontRequest(tenant, fontId) {
    return doHttpCall('DELETE', `/admin/v1/preferences/${tenant}/fonts/byId/${fontId}`, {}, {});
  },

  async updateFontFaceRequest(tenant, fontId, fontFaceId, data) {
    return doHttpCall('POST', `/admin/v1/preferences/${tenant}/fonts/byId/${fontId}/face${fontFaceId}`, data, {});
  },

  async deleteFontFaceRequest(tenant, fontId, fontFaceId) {
    return doHttpCall('DELETE', `/admin/v1/preferences/${tenant}/fonts/byId/${fontId}/face/${fontFaceId}`, {}, {});
  },

  async uploadFontFaceFileRequest(tenant, fontId, fontFaceId, fileData) {
    return doHttpCall(
      'POST_FONT',
      `/admin/v1/preferences/${tenant}/fonts/byId/${fontId}/face/${fontFaceId}/file`,
      fileData,
      {}
    );
  },

  async getCustomFontsListRequest(tenant) {
    return doHttpCall('GET', `/admin/v1/preferences/${tenant}/fonts/list`, {}, {});
  },

  async exportZIPTemplatesRequest(tenant) {
    return doHttpCall('GET_PDF', `/admin/v1/comm-template/${tenant.accountId}/${tenant.id}/export`, {}, {});
  },

  async importZIPTemplatesRequest(account, tenant, data) {
    return doHttpCall('POST_ZIP', `/admin/v1/comm-template/${account}/${tenant}/import`, data, {});
  },

  async getGuardianInfoRequest(userId) {
    return doHttpCall('GET', `/identity/v1/userrelationship/getdetails/${userId}`, {}, {});
  },

  async createGuardianInfoRequest(data) {
    return doHttpCall('POST', '/identity/v1/userrelationship/create', data, {});
  },

  async updateGuardianInfoRequest(data) {
    return doHttpCall('PUT', '/identity/v1/userrelationship/update', data, {});
  },

  async getUserVaxStatusHistoryRequest(safeEMRId) {
    return doHttpCall('GET', `/patient-record/v1/Vaccination/getcovidvaccinestatus/${safeEMRId}`, {}, {});
  },

  async manageUserVaxStatusRequest(data, tenantId) {
    return doHttpCall('POST', `/patient-record/v1/Vaccination/managevaccinationstatusdetails/${tenantId}`, data, {});
  },

  async searchRequest(searchClinical, type, data) {
    const url = searchClinical
      ? `/mastercode/v1/Typeahead/clinicaltables/search${type}`
      : `/mastercode/v1/Typeahead/search${type}`;
    return doHttpCall('POST', url, data, {});
  },
  async setMemberPhotoRequest(username, payload) {
    return doHttpCall('POST_PHOTO', `/admin/v1/user/pic?username=${username}`, payload, {});
  },

  async getConsentsRequest(tenant, params) {
    return doHttpCall('GET', `/admin/v1/user/consent/${tenant}`, {}, params);
  },

  async getVaccinationRecordImage(safeEMRId, imageId) {
    return doHttpCall('GET', `/patient-record/v1/vaccination/vaccinerecords/${safeEMRId}/image/${imageId}`, {}, {});
  },
  async getVaccinationRecords(safeEMRId, pageInfo) {
    return doHttpCall(
      'GET',
      `/patient-record/v1/vaccination/vaccinerecords/${safeEMRId}` +
        `?base64Image=false&hasSortPagingFilter=true&pageNumber=${pageInfo.page}` +
        `&pageSize=${pageInfo.size}&sort=${pageInfo.sort}&direction=${pageInfo.direction.toUpperCase()}`,
      {},
      {}
    );
  },
  async getLatestVaccinationRecord(id) {
    return doHttpCall('GET', `/patient-record/v1/vaccination/vaccinerecord/${id}?base64Image=true`);
  },
  async getMasterCode(data) {
    return doHttpCall('POST', '/mastercode/v1/Typeahead/searchImmunization', data);
  },

  async updateAttestation(data, safeEMRId) {
    return doHttpCall('UPDATE_ATTESTATION', `/patient-record/v1/vaccination/vaccinerecords/${safeEMRId}`, data, {});
  },

  async createAttestation(data, safeEMRId) {
    return doHttpCall('CREATE_ATTESTATION', `/patient-record/v1/vaccination/vaccinerecords/${safeEMRId}`, data, {});
  },

  async approveAttestation(safeEMRId, recordId) {
    return doHttpCall('POST', `/patient-record/v1/vaccination/vaccinerecords/${safeEMRId}/approve/${recordId}`, {});
  },

  async getQuestionnaireContentRequest(tenant, type, params) {
    return doHttpCall('GET', `/virtual-consult/v1/${tenant}/${type}`, {}, params);
  },

  async getUserSegment(tenant) {
    return doHttpCall('GET', `/admin/v1/user-segment/${tenant}`, {}, {});
  },

  async getPhoneLookupRequest(number, accountId) {
    return doHttpCall('GET', `/identity/v1/users/lookup/phone/${number}`, {}, accountId);
  },

  async deleteQuestionRequest(tenant, id) {
    return doHttpCall('DELETE', `/virtual-consult/v1/${tenant}/questions/${id}`, {}, {});
  },

  async deleteQuestionnaireRequest(tenant, id) {
    return doHttpCall('DELETE', `/virtual-consult/v1/${tenant}/questionnaires/${id}`, {}, {});
  },

  async deleteOutcomeRequest(tenant, id) {
    return doHttpCall('DELETE', `/virtual-consult/v1/${tenant}/outcomes/${id}`, {}, {});
  },
  async deleteUserSegmentRequest(tenant, segmentId) {
    return doHttpCall('DELETE', `/admin/v1/user-segment/${tenant}/${segmentId}`, {}, {});
  },
  async bulkUploadQuestionnaire(tenant, type, data) {
    return doHttpCall('POST', `/virtual-consult/v1/${tenant}/${type}/bulk`, data, {});
  },
  async getQuestionnaireConfigRequest(tenant) {
    return doHttpCall('GET', `/virtual-consult/v1/${tenant}/configuration`, {}, {});
  },
  async updateQuestionnaireConfigRequest(tenant, config) {
    return doHttpCall('PUT', `/virtual-consult/v1/${tenant}/configuration`, config, {});
  },
  async createOutcomeRequest(tenant, data) {
    return doHttpCall(
      'POST',
      `/virtual-consult/v1/${tenant}/outcomes`,
      {
        ...data,
        tenantId: tenant,
      },
      {}
    );
  },
  async updateOutcomeRequest(tenant, data, id) {
    return doHttpCall('PUT', `/virtual-consult/v1/${tenant}/outcomes/${id}`, data, {});
  },
  async getTagsRequest(tenant, query) {
    return doHttpCall('GET', `/virtual-consult/v1/${tenant}/tags`, {}, { q: query });
  },
  async getContentByIdRequest(tenant, type, id) {
    return doHttpCall('GET', `/virtual-consult/v1/${tenant}/${type}/${id}`, {}, {});
  },
  async createQuestionRequest(tenant, data) {
    return doHttpCall('POST', `/virtual-consult/v1/${tenant}/questions`, { ...data }, {});
  },
  async updateQuestionRequest(tenant, id, data) {
    return doHttpCall('PUT', `/virtual-consult/v1/${tenant}/questions/${id}`, data, {});
  },
  async createQuestionnaireRequest(tenant, data) {
    return doHttpCall('POST', `/virtual-consult/v1/${tenant}/questionnaires`, data, {});
  },
  async updateQuestionnaireRequest(tenant, id, data) {
    return doHttpCall('PUT', `/virtual-consult/v1/${tenant}/questionnaires/${id}`, data, {});
  },
  async createUserSegmentRequest(tenant, segment) {
    return doHttpCall('POST', `/admin/v1/user-segment/${tenant}`, segment, {});
  },
  async updateUserSegmentRequest(tenant, segment) {
    return doHttpCall('PUT', `/admin/v1/user-segment/${tenant}/${segment.id}`, segment, {});
  },
  async verifyAuthenticationCodeRequest(tenant, request) {
    return doHttpCall('POST', `/vcb-ehr/v1/member/verification/CheckAuthenticationCode/${tenant}`, request, {});
  },
  async getTemplateTenantRequest(id) {
    return doHttpCall('GET', `/admin/v1/tenant/byAccount/${id}/template`, {}, {});
  },
  async changeUserStatusRequest(data) {
    return doHttpCall('POST', '/identity/v1/users/set/status', data, {});
  },

  async getAllUploadsRequest(tenant, params) {
    return doHttpCall(
      'GET',
      // `/identity/v1/importusers/reports?accountId=healthcheck&tenantId=healthcheck-consumer-development&pageNumber=0&pageSize=10`,
      '/identity/v1/importusers/reports',
      {},
      params
    );
  },

  async downloadUploadingFileRequest(params) {
    return doHttpCall('GET', '/identity/v1/importusers/reports/download', {}, params);
  },

  async getUserTotalsRequest() {
    return doHttpCall('GET', '/identity/v1/user/getUsersTotals', {}, {});
  },

  // SIMULATOR APIs
  async postSimulateTestKit(status, payload) {
    return doHttpCall('POST', `/apisimulator/v1/firstview/test-kit/${status}`, payload);
  },
  async postSimulateConsult(status, payload) {
    return doHttpCall('POST', `/apisimulator/v1/firstview/consult/${status}`, payload);
  },
  async postSimulateRxOrder(status, payload) {
    return doHttpCall('POST', `/apisimulator/v1/firstview/rx-order/${status}`, payload);
  },
  async getSimulateEmployeeBenefit(tenantId, userId) {
    return doHttpCall('GET', `/apisimulator/v1/${tenantId}/employee-benefit/${userId}`);
  },
  async postSimulateEmployeeBenefit(tenantId, userId, payload) {
    return doHttpCall('POST', `/apisimulator/v1/${tenantId}/employee-benefit/${userId}`, payload);
  },
  async postSimulateDoordash(event, payload) {
    return doHttpCall('POST', `/apisimulator/v1/doordash/${event}`, payload);
  },
  async postSimulateNetsuite(event, payload) {
    return doHttpCall('POST', `/apisimulator/v1/netsuite/${event}`, payload);
  },
  async getSimulateWelcomeKit(userId) {
    return doHttpCall('GET', `/apisimulator/v1/user/${userId}/claimed-welcome-kit`);
  },
  async patchSimulateWelcomeKit(userId, payload) {
    return doHttpCall('PATCH', `/apisimulator/v1/user/${userId}/claimed-welcome-kit`, payload);
  },
  async getSimulateExternalId(userId) {
    return doHttpCall('GET', `/apisimulator/v1/user/${userId}/external-id`);
  },
  async patchSimulateExternalId(userId, payload) {
    return doHttpCall('PATCH', `/apisimulator/v1/user/${userId}/external-id`, payload);
  },
  async getSimulateFvSubscriber(tenantId, externalId) {
    return doHttpCall('GET', `/apisimulator/v1/firstview/${tenantId}/user/${externalId}`);
  },
  async postUpdateFvSubscriber(tenantId, externalId, payload) {
    return doHttpCall('POST', `/apisimulator/v1/firstview/${tenantId}/user/${externalId}`, payload);
  },
  async postUpdateFvDependent(tenantId, externalId, depExternalId, payload) {
    return doHttpCall(
      'POST',
      `/apisimulator/v1/firstview/${tenantId}/user/${externalId}/dependents/${depExternalId}`,
      payload
    );
  },
  async postAddFvDependent(tenantId, externalId, payload) {
    return doHttpCall('POST', `/apisimulator/v1/firstview/${tenantId}/user/${externalId}/dependents`, payload);
  },
  async patchSimulateInvalidateInsurance(userId) {
    return doHttpCall('PATCH', `/apisimulator/v1/user/${userId}/insurance/invalidate`)
  },
  async postSimulateEncounter(userId) {
    console.log(userId)
    return doHttpCall('POST', `/apisimulator/v1/user/${userId}/encounter`, {})
  },

  async getDosespotSSOUrl(patientId) {
    return doHttpCall('GET', `/eprescription/v1/dosespot-sso-url/${patientId}/111222333`);
  },

  async syncWithDosespot(patientId) {
    return doHttpCall('POST', `/eprescription/v1/synpatientdetailstoshs`, {
      safePatientId: patientId,
      safeEncounterId: null,
    });
  },
};

export default API;

function createResponse(result, errorMessage, status) {
  const error = errorMessage && { message: errorMessage, status };
  return [result, error];
}

async function doHttpCall(method, url, payload, params, requireToken = true, extraHeaders = {}, cancelToken) {
  try {
    const span = tracer.startSpan(url);

    const idToken = lsClient.tokens.getIdToken();
    const accountId = localStorage.getItem('accountId');
    const tenantId = localStorage.getItem('tenantId');
    span.setAttribute('accountId', accountId);
    span.setAttribute('tenantId', tenantId);
    span.addEvent(`http request ${method}`);
    let result = null;
    let traceId = span._spanContext.traceId;
    let spanId = span._spanContext.spanId;
    const trace = `00-${traceId}-${spanId}-01`;
    let headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json;charset=UTF-8',
      Accept: 'application/json',
    };
    headers['traceparent'] = trace;

    if (requireToken && idToken) {
      headers.Authorization = `Bearer ${idToken}`;
      headers.Id_Token = idToken;
      headers['X-SF-ACCOUNT'] = accountId;
      headers['X-SF-TENANT'] = tenantId;
    }

    if (Object.keys(extraHeaders).length) headers = { ...headers, ...extraHeaders };

    if (method === 'GET') {
      result = await httpClient.get(url, { headers, params });
    } else if (method === 'POST') {
      result = await httpClient.post(url, { ...payload }, { headers, params });
    } else if (method === 'PATCH') {
      result = await httpClient.patch(url, { ...payload }, { headers, params });
    } else if (method === 'PUT') {
      result = await httpClient.put(url, Array.isArray(payload) ? payload : { ...payload }, { headers, params });
    } else if (method === 'DELETE') {
      result = await httpClient.delete(url, { headers, params: payload });
    } else if (method === 'GET_PHOTO') {
      result = await httpClient.get(url, {
        headers,
        params,
        responseType: 'blob',
      });
    } else if (method === 'GET_ARRAYBUFFER') {
      result = await httpClient.get(url, {
        headers,
        params,
        responseType: 'arraybuffer',
      });
    } else if (method === 'POST_PHOTO') {
      const formData = new FormData();
      formData.append('image', payload);
      result = await httpClient.post(url, formData, {
        headers: {
          ...headers,
          'Content-Type': 'multipart/form-data',
        },
      });
    } else if (method === 'UPDATE_ATTESTATION') {
      const formData = new FormData();
      formData.append('id', payload.id);
      formData.append('type', payload.type);
      formData.append('vaccinations', payload.vaccinations);

      payload.images.forEach((image, idx) => {
        if (image.hasOwnProperty('id')) {
          formData.append(`imageRequests[${idx}].id`, image.id);
        } else {
          formData.append(`imageRequests[${idx}].image`, image.file);
          formData.append(`imageRequests[${idx}].id`, '');
        }
      });

      result = await httpClient.post(url, formData, {
        headers: {
          ...headers,
          'Content-Type': 'multipart/form-data',
        },
      });
    } else if (method === 'CREATE_ATTESTATION') {
      const formData = new FormData();
      formData.append('type', payload.type);
      payload.images.forEach((image, idx) => {
        formData.append(`imageRequests[${idx}].image`, image);
        formData.append(`imageRequests[${idx}].id`, '');
      });
      formData.append('vaccinations', payload.vaccinations);
      result = await httpClient.post(url, formData, {
        headers: {
          ...headers,
          'Content-Type': 'multipart/form-data',
        },
      });
    } else if (method === 'GET_PDF') {
      result = await httpClient.get(url, {
        headers: {
          ...headers,
          Accept: 'application/pdf',
        },
        params,
        responseType: 'blob',
      });
    } else if (method === 'POST_SCREENSHOT') {
      const formData = new FormData();
      formData.append('file', payload);
      result = await httpClient.post(url, formData, {
        headers: {
          ...headers,
          'Content-Type': 'multipart/form-data',
        },
      });
    } else if (method === 'POST_FILE') {
      const formData = new FormData();
      if (payload.data) {
        formData.append('dataset', payload.data);
      } else if (payload.file) {
        formData.append('file', payload.file);
        formData.append('account', payload.account);
        formData.append('creatorEmail', payload.creatorEmail);
        formData.append('tenant', payload.tenant);
      }

      if (payload.forceUpload) formData.append('force', String(payload.forceUpload));
      if (payload.preview) formData.append('preview', String(payload.preview));

      result = await httpClient.post(url, formData, {
        headers: {
          ...headers,
          'Content-Type': 'multipart/form-data',
        },
      });
    } else if (method === 'ZENDESK_POST') {
      headers = {
        'Content-Type': 'application/json;charset=UTF-8',
        Accept: 'application/json',
      };
      if (extraHeaders) {
        headers = {
          ...headers,
          ...extraHeaders,
        };
      }
      result = await httpClient.post(url, payload, { headers });
    } else if (method === 'POST_FONT') {
      const formData = new FormData();
      formData.append('fontFile', payload);

      result = await httpClient.post(url, formData, {
        headers: {
          ...headers,
          'Content-Type': 'multipart/form-data',
        },
      });
    } else if (method === 'POST_ZIP') {
      const formData = new FormData();
      formData.append('zip', payload);

      result = await httpClient.post(url, formData, {
        headers: {
          ...headers,
          'Content-Type': 'multipart/form-data',
        },
      });
    } else if (method === 'POST_USERS') {
      // result = await httpClient.post(url, { ...payload }, { headers, params });
      result = await httpClient.post(url, { ...payload }, { headers, params, cancelToken });
    }

    if (!(result && result.data)) {
      span.end();
      return createResponse(null, 'Data not found');
    }
    span.end();
    return createResponse(result.data);
  } catch (error) {
    const status = error?.response?.status;

    // [SAF-2546] - when user changes filter too fast, previous api calls should be cancelled. Do not throw error
    if (httpClient.isCancel(error)) {
      // eslint-disable-next-line no-console
      console.log('Previous request cancelled, new request sent');
      return createResponse(null, 'Previous request cancelled', 499);
    }

    if (error?.response?.data) {
      const errorMessage =
        error?.response?.data?.errorMessage ||
        error?.response?.data?.message ||
        error?.response?.data?.Message ||
        error?.response?.data;
      /* eslint-disable-next-line */
      console.log(errorMessage);
      return createResponse(null, errorMessage, status);
    }
    if (error.request) {
      // eslint-disable-next-line
      console.log(error.request);
    } else {
      // eslint-disable-next-line
      console.log('Error', error.message);
    }
    return createResponse(null, 'Unknown error', status);
  }
}
