import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useParams } from 'react-router-dom';

// imports
import AppStyles from '../assets/jss/components/AppStyles';
import NetworkPageWrapper from '../components/Networks/NetworkPageWrapper';
import AppBreadcrumbs from '../components/Shared/Breadcrumbs';

function NetworkView() {
  const params = useParams();

  const { type } = params;
  const { assetsType } = params;

  const classes = useStyles();

  return (
    <div className={classes.updatedStyles}>
      <AppBreadcrumbs />
      <NetworkPageWrapper type={type} assetsType={assetsType} />
    </div>
  );
}

const useStyles = makeStyles(AppStyles);

export default NetworkView;
