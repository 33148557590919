const DateRangeStyles = (theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down(420)]: {
      marginRight: -20,
    },
  },
  oldButton: {
    width: 80,
    fontSize: '10px',
    height: 30,
    margin: 5,
    borderRadius: 10,
    backgroundColor: '#FFF',
    fontFamily: 'Averta Regular',
    [theme.breakpoints.down(420)]: {
      width: 75,
    },
  },
  button: {
    width: 80,
    fontSize: '10px',
    height: 30,
    marginRight: 15,
    border: '1px solid #929292',
    borderRadius: 8,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    color: '#929292',
    fontFamily: 'Averta Regular',
    [theme.breakpoints.down(420)]: {
      width: 75,
    },
  },
  oldButtonSelected: {
    width: 80,
    fontSize: '10px',
    height: 30,
    margin: 5,
    borderRadius: 10,
    color: '#FFF',
    backgroundColor: theme.palette.primary.main,
    fontFamily: 'Averta Regular',
    [theme.breakpoints.down(420)]: {
      width: 75,
    },
  },
  buttonSelected: {
    width: 80,
    fontSize: '10px',
    height: 30,
    marginRight: 15,
    border: '2px solid #003C71',
    borderRadius: 8,
    color: '#003C71',
    backgroundColor: 'rgba(255, 255, 255, 0)',
    fontFamily: 'Averta Regular',
    [theme.breakpoints.down(420)]: {
      width: 75,
    },
  },
  dateRangeTitle: {
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 22,
    color: '#505358',
    lineHeight: '33px',
  },
  dateRangeValue: {
    marginLeft: 10,
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: 22,
    color: '#505358',
    lineHeight: '33px',
  },
  buttonPannel: {
    '& .k-daterangepicker': {
      marginTop: '-5px',
      color: '#6E6E6E',
      '& .k-dateinput-wrap': {
        color: '#6E6E6E',
        borderColor: '#CBCCCD',
      },
    },
  },
  dateLabel: {
    '& .k-label': {
      maxWidth: '105%',
    },
  },
});

export default DateRangeStyles;
