import React from 'react';
import { useParams } from 'react-router-dom';
// component imports
import AppBreadcrumbs from '../components/Shared/Breadcrumbs';
import UserImportStatus from '../components/Users/UserImportStatus';

function ImportStatusView() {
  const params = useParams();
  const { id } = params;
  return (
    <div>
      <AppBreadcrumbs />
      <UserImportStatus id={id} />
    </div>
  );
}

export default ImportStatusView;
