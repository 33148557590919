const SettingsFontsStyles = (theme) => ({
  container: {
    width: '92vw',
    [theme.breakpoints.up('md')]: {
      width: '50vw',
      margin: 'auto',
    },
    '& div.MuiGrid-spacing-xs-2': {
      textAlign: 'center',
      justifyContent: 'center',
    },
    '& div#button': {
      margin: '10px auto',
      position: 'relative',
    },
  },
  loadingSaveFontWrapper: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  fontFaceBox: {
    '& h4': {
      marginTop: '1px',
      fontSize: '16px',
      fontWeight: 'bold',
      marginBottom: '10px',
    },
  },
  allowFormatFiles: {
    position: 'absolute',
    width: '200px',
    fontSize: '12px',
    fontWeight: 'normal',
    margin: '5px 0',
    color: '#a7a7a7',
  },
  fontFaceSettingsLabel: {
    fontSize: '12px!important',
    fontWeight: 'normal!important',
    marginBottom: '5px!important',
  },
  uploadedFileName: {
    fontSize: '14px',
    fontWeight: 'normal',
    marginLeft: '10px',
  },
  InputLabelProps:{
    fontSize: '16px',
    position: 'absolute',
    top: '-10px',
    left: '-4px',
    backgroundColor: '#fff',
    padding: '8px',
  }
});

export default SettingsFontsStyles;
