const UserSegmentsSettingsStyles = () => ({
  container: {
    padding: '8px 24px 24px 8px',
  },
  loadingUserSegmentEditWrapper: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  radioHeader: {
    position: 'absolute',
    width: '104px',
    textAlign: 'center',
    fontSize: '14px',
    lineHeight: '1',
    top: '-20px',
  },
});

export default UserSegmentsSettingsStyles;
