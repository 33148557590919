import React from 'react';
// imports
import { useParams } from 'react-router-dom';
import PageBreadcrumbs from '../components/Shared/Breadcrumbs';
import UserPageWrapper from '../components/Users/UserPageWrapper';

function UsersView() {
  const params = useParams();
  const { userType } = params;

  return (
    <div>
      <PageBreadcrumbs userType={userType} />
      <UserPageWrapper userType={userType} />
    </div>
  );
}

export default UsersView;
