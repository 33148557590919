const DiscussionStyles = () => ({
  discussionBlock: {
    // border: "1px solid black",
    // borderTop: "1px solid #cccccc",
    // paddingTop: 30,
  },
  popover: {
    '&>.MuiPopover-paper': {
      padding: '27px 10px 10px 10px',
      width: 300,
      maxHeight: 297,
      // minHeight: 96,
      overflowY: 'hidden',
    },
  },
  messages: {
    width: '100%',
    maxHeight: 175,
    minHeight: 96,
    overflowY: 'auto',
    marginBottom: 10,
    paddingRight: 3,
    // height: 200,
    // overflowY: "auto",
  },
  messageWrapper: {
    marginBottom: 5,
  },
  message: {
    // maxWidth: 600,
    margin: '0 auto',
    display: 'flex',
    flex: 1,
    flexFlow: 'column wrap',
    '&>.bubble': {
      borderRadius: '1em',
      padding: '0.25em 0.75em',
      margin: '0.0625em',
      maxWidth: '70%',
    },
    '&>.bubble.sender': {
      alignSelf: 'flex-start',
      backgroundColor: 'cornflowerblue',
      color: '#fff',
    },
    '&>.bubble.recipient': {
      alignSelf: 'flex-end',
      backgroundColor: '#efefef',
    },

    '&>.bubble.sender.first': {
      borderBottomLeftRadius: '0.1em',
    },
    '&>.bubble.sender.last': {
      borderTopLeftRadius: '0.1em',
    },
    '&>.bubble.sender.middle': {
      borderBottomLeftRadius: '0.1em',
      borderTopLeftRadius: '0.1em',
    },

    '&>.bubble.recipient.first': {
      borderBottomRightRadius: '0.1em',
    },
    '&>.bubble.recipient.last': {
      borderTopRightRadius: '0.1em',
    },
    '&>.bubble.recipient.middle': {
      borderBottomRightRadius: '0.1em',
      borderTopRightRadius: '0.1em',
    },
  },
  senderIcon: {
    textAlign: 'center',
    width: 36,
    height: 36,
    // border: "1px solid #909090",
    borderRadius: ' 100%',
    marginRight: 7,
    fontSize: 17,
    lineHeight: '36px',
    fontWeight: 500,
    color: 'white',
  },
  userName: {
    color: '#7b7b7b',
    fontSize: 13,
  },
  messageDate: {
    fontSize: '11px',
    textAlign: 'end',
    marginTop: 5,
  },
  emptyIcon: {
    width: 36,
    height: 36,
    marginRight: 7,
  },
  sendIcon: {
    position: 'absolute',
    bottom: -2,
    right: -6,
    transform: 'rotate(-35deg)',
    cursor: 'pointer',
  },
  closeMessengerIcon: {
    position: 'absolute',
    top: -10,
    right: -10,
    zIndex: 1,
  },
});

export default DiscussionStyles;
