export const lsClient = {
  tokens: {
    getAccessToken() {
      return localStorage.getItem('access_token');
    },
    getIdToken() {
      return localStorage.getItem('id_token');
    },
  },
};
