import React from 'react';
import { useParams } from 'react-router-dom';

// imports
import ContentFormWrapper from '../components/Content/ContentFormWrapper';
import AppBreadcrumbs from '../components/Shared/Breadcrumbs';
import utils from '../components/Shared/Utils/utils';

function ContentCreateView() {
  const params = useParams();
  const type = utils.snakeToCamel(params.type);
  return (
    <div>
      <AppBreadcrumbs />
      <ContentFormWrapper type={type} />
    </div>
  );
}

export default ContentCreateView;
