import React from 'react';

// imports
import EncountersListWrapper from '../components/Encounters/EncountersListWrapper';
import AppBreadcrumbs from '../components/Shared/Breadcrumbs';

function EncountersView(props) {
  const { match } = props;

  return (
    <div>
      <AppBreadcrumbs match={match} />
      <EncountersListWrapper />
    </div>
  );
}

export default EncountersView;
