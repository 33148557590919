// material-UI
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import NotFoundViewStyles from '../assets/jss/components/NotFoundViewStyles';
import { selectCurrentTenant } from '../components/Shared/selectors';

export function NotFoundView(props) {
  const { classes, message } = props;
  const currentTenant = useSelector(selectCurrentTenant);
  const navigate = useNavigate();

  return (
    <Grid container justifyContent="center">
      <Grid item className={classes?.cardContainer}>
        <Typography variant="h1" className={classes.errorCode}>
          404
        </Typography>

        <Typography variant="h3" className={classes.errorHeader}>
          Oops!
        </Typography>

        <Typography variant="h3" className={classes.errorDescription}>
          {message || 'We can’t seem to find the page you are looking for.'}
        </Typography>

        {currentTenant && currentTenant.id ? (
          <Button
            color="primary"
            variant="contained"
            style={{ marginTop: '55px', borderRadius: '5px' }}
            onClick={() => navigate(`/${currentTenant.id}`)}
          >
            Go Home
          </Button>
        ) : null}
      </Grid>
    </Grid>
  );
}

export default withStyles(NotFoundViewStyles)(NotFoundView);
