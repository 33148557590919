const AlertsStyles = (theme) => ({
  notification: {
    margin: 'auto',
  },
  box: {
    maxWidth: '90vw',
    [theme.breakpoints.up('md')]: {
      maxWidth: '550px',
    },
  },
  unreadNotification: {
    backgroundColor: '#EDF2F9',
    // marginBottom: 2,
    // marginTop: 2
  },
  lensIcon: {
    color: '#FF6200',
    fontSize: 14,
  },
  customBadge: {
    backgroundColor: '#FF6200',
    color: 'white',
  },
  progressStyles: {
    height: 19,
    borderRadius: 19,
  },
  nbOfUsersToProcessing: {
    marginTop: 8,
  },
  barColorSuccess: {
    backgroundColor: '#79DE76',
  },
});

export default AlertsStyles;
