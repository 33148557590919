import React from 'react';
import VBreadcrumbs from '../components/Shared/Breadcrumbs';
import VMonitoringPageWrapper from '../components/VirtualConsult/Monitoring/MonitoringPageWrapper';
import ReviewerWrapper from '../components/VirtualConsult/Reviewer/ReviewerWrapper';
import VUserSegmentPageWrapper from '../components/VirtualConsult/UserSegment/UserSegmentPageWrapper';
import VConsultListWrapper from '../components/VirtualConsult/VirtualConsultListWrapper';

function VirtualConsultView(props) {
  const { type = 'questionnaires' } = props;

  return (
    <div>
      <VBreadcrumbs />
      {type === 'userSegments' && <VUserSegmentPageWrapper />}
      {type === 'monitoring' && <VMonitoringPageWrapper />}
      {type === 'reviewer' && <ReviewerWrapper />}
      {type !== 'userSegments' && type !== 'monitoring' && type !== 'reviewer' && <VConsultListWrapper type={type} />}
    </div>
  );
}

export default VirtualConsultView;
