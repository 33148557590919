const AppStyles = (theme) => ({
  appWrapper: {
    paddingTop: 30,
    paddingBottom: 30,
    [theme.breakpoints.up('md')]: {
      paddingTop: 10,
    },
  },
  sidebar: {
    paddingLeft: 0,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 50,
    },
  },
  loading: {
    marginTop: '100px',
  },
  breadcrumbs: {
    marginLeft: -26,
    marginTop: 3,
    marginBottom: 15,
    [theme.breakpoints.up('sm')]: {
      marginLeft: -26,
      marginTop: 45,
      marginBottom: 15,
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: -26,
      marginTop: 20,
      marginBottom: 15,
    },
  },
  buttonLoading: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    opacity: '0.8',
    zIndex: 1,
  },
  updatedStyles: {
    "& .MuiButton-root": {
      borderWidth: "2px!important",
      borderRadius: 32,
      textTransform: "none",
      fontSize: 16,
      fontWeight: 600,
      fontFamily: "Avenir",
      lineHeight: "27.2px",
    },
    // kendo field style changes
    "& .outlined": {
      "& .k-textbox-container": {
        border: "1px solid #79747E",
        borderRadius: 5,
        paddingLeft: 20,
        paddingRight: 12,
        "& .k-select": {
          marginTop: -13,
        },
        "& .k-clear-value": {
          marginTop: -7,
        },
        "& .k-combobox .k-dropdown-wrap.k-state-disabled": {
          borderBottomStyle: "none",
        },
        "& .k-textbox": {
          border: "none",
          height: "calc( 1.125em + 16px)",
        },
        "& .k-numeric-wrap": {
          border: "none",
        },
        "& .k-multiselect-wrap": {
          border: "none",
        },
      },
      "& .k-textbox-container:after": {
        height: 0,
      },
      "& .k-dropdown-wrap": {
        border: "none",
      },
      "& .k-textbox-container.k-state-empty": {
        "&>.k-label": {
          top: "calc( calc( 1.125 * 16px))",
        },
      },
      "& .k-textbox-container:focus-within": {
        "&>.k-label": {
          top: "0!important",
        },
      },
    },
    "& .outlined.disabled": {
      // Kendo textfield outlined customization
      "& .k-textbox-container": {
        border: "1px dashed #79747E",
      },
    }
  },
});

export default AppStyles;
