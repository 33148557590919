import React from 'react';

// imports
import AppMedicalCentersPageWrapper from '../components/MedicalCenters/MedicalCentersPageWrapper';
import AppBreadcrumbs from '../components/Shared/Breadcrumbs';

function MedicalCenterView(props) {
  const { type } = props;

  return (
    <div>
      <AppBreadcrumbs />
      <AppMedicalCentersPageWrapper type={type} />
    </div>
  );
}

export default MedicalCenterView;
