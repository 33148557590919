import 'react-app-polyfill/ie11';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { showNotification } from './components/Notifications/notificationSlice';
import history from './components/Shared/Redux/history';
import { defaultStore } from './components/Shared/Redux/stores/defaultStore';
import * as serviceWorker from './serviceWorker';

// Components

render(
  <Provider store={defaultStore}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.register();

const channel = new BroadcastChannel('sw-messages');

channel.addEventListener('message', (event) => {
  const { isNewUIVersion } = event.data;
  if (!isNewUIVersion) return;

  defaultStore.dispatch(showNotification('A new version is available.', 'info', true, true));
});
