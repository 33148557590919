const NotFoundViewStyles = () => ({
  cardContainer: {
    width: '817px',
    height: '670px',
    borderRadius: '24px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '7%',
  },
  errorCode: {
    color: '#2196F3',
    fontWeight: '300',
    fontSize: '260px',
    lineHeight: '1',
  },
  errorHeader: {
    height: '60px',
    color: '#000000',
    fontWeight: '300',
    fontSize: '60px',
    lineHeight: '0.8',
    marginTop: '19px',
  },
  errorDescription: {
    width: '348px',
    textAlign: 'center',
    color: '#616161',
    fontWeight: '400',
    fontSize: '24px',
    lineHeight: '1.36',
    marginTop: '14px',
  },
});

export default NotFoundViewStyles;
