const SettingsTemplatesStyles = () => ({
  paper: {
    // padding: 20,
    // height: 400,
    // borderRadius: 20,
    overflow: 'auto',
  },
  smsPaper: {
    padding: 20,
    borderRadius: 20,
  },
  resetBtn: {
    '&:hover': {
      opacity: '50%',
      cursor: 'pointer',
    },
  },
  variableKey: {
    '&:hover': {
      opacity: '50%',
      cursor: 'copy',
    },
  },
});

export default SettingsTemplatesStyles;
