import axios from 'axios';

const httpClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_ROOT}`,
});

// [SAF-2546] Fix when user change filter too fast in the User table
httpClient.CancelToken = axios.CancelToken;
httpClient.isCancel = axios.isCancel;

export default httpClient;
