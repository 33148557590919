const CreateOrderDialogStyles = (theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  actionButton: {
    margin: theme.spacing(2, 0, 4),
  },
  dialogContainer: {
    '& .MuiPaper-root': {
      borderRadius: 20,
      padding: 32,
      boxSizing: 'border-box',
    },
  },
});

export default CreateOrderDialogStyles;
