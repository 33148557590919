const SearchbarStyles = (theme) => ({
  searchText: {
    flex: '0.8 0',
  },
  clearIcon: {
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
  searchTextOutlined: {
    '& input': {
      padding: '10px 0!important',
    },
  },
});

export default SearchbarStyles;
