import React from 'react';
import { useParams } from 'react-router-dom';

// imports
import DiagnosticReport from '../components/DiagnosticReport/DiagnosticReport';
import AppBreadcrumbs from '../components/Shared/Breadcrumbs';

function DiagnosticReportView(props) {
  const { type } = props;
  const params = useParams();
  const { userId } = params;

  return (
    <div>
      <AppBreadcrumbs userId={userId} />
      <DiagnosticReport type={type} />
    </div>
  );
}

export default DiagnosticReportView;
