const CreateOrderDialogStyles = (theme) => ({
  root: {
    margin: 'auto',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  selectedList: {
    height: 462,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  list: {
    height: 300,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  actionButton: {
    margin: theme.spacing(2, 0, 4),
  },
});

export default CreateOrderDialogStyles;
