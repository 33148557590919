import React from 'react';

// imports
import ReportingPageWrapper from '../components/Reporting/ReportingPageWrapper';
import AppBreadcrumbs from '../components/Shared/Breadcrumbs';

function ReportingView(props) {
  const { type } = props;

  return (
    <div>
      <AppBreadcrumbs />
      <ReportingPageWrapper type={type} />
    </div>
  );
}

export default ReportingView;
