import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import React from 'react';
import { useSelector } from 'react-redux';

// Styles
import AppStyles from './assets/jss/components/AppStyles';
import './styles/Kendo.scss';
import './styles/index.css';
// components
import { FullscreenLoader } from './components/AppLoader/FullscreenLoader';
import { AuthLayout } from './components/Auth/AuthWrapper';
import { selectAuthToken } from './components/Auth/selectors';
import { Layout } from './components/Layout';
import AppNotification from './components/Notifications/Notification';
import { useAppInit } from './components/Shared/hooks/useAppInit';
import { renderRoutes } from './routes/index';

function App() {
  const isLoggedIn = useSelector(selectAuthToken);

  const { isAppLoading, theme } = useAppInit();

  if (isAppLoading || !theme)
    return (
      <>
        <AppNotification />
        <FullscreenLoader />
      </>
    );

  return (
    <MuiThemeProvider theme={createTheme(theme)}>
      <CssBaseline />
      <AppNotification />
      <AuthLayout>
        <Layout>{renderRoutes(isLoggedIn)}</Layout>
      </AuthLayout>
    </MuiThemeProvider>
  );
}

export default withStyles(AppStyles)(App);
