import React from 'react';
import { useParams } from 'react-router-dom';

// imports
import ContentFormWrapper from '../components/Content/ContentFormWrapper';
import AppBreadcrumbs from '../components/Shared/Breadcrumbs';
import utils from '../components/Shared/Utils/utils';

function AssetsIntroWidgetEditView() {
  const params = useParams();
  const type = utils.snakeToCamel(params.type);
  const { contentId } = params;

  return (
    <div>
      <AppBreadcrumbs />
      <ContentFormWrapper type={type} id={contentId} edit />
    </div>
  );
}

export default AssetsIntroWidgetEditView;
