import React from 'react';
import { useParams } from 'react-router-dom';

// imports
import SettingsVerbiagePageWrapper from '../components/Settings/ExpansionComponents/SettingsVerbiagePageWrapper';
import SettingsTemplateEditorWrapper from '../components/Settings/SystemMessages/SettingsTemplateEditorWrapper';
import SystemMessagesPageWrapper from '../components/Settings/SystemMessages/SystemMessagesPageWrapper';
import AppBreadcrumbs from '../components/Shared/Breadcrumbs';

function SettingsView(props) {
  const { type } = props;
  const params = useParams();
  const { tabType } = params;

  return (
    <div>
      <AppBreadcrumbs />
      {type === 'templates' && <SystemMessagesPageWrapper messageType={tabType} />}
      {type === 'editor' && <SettingsTemplateEditorWrapper />}
      {type === 'verbiage' && <SettingsVerbiagePageWrapper tabType={tabType} />}
    </div>
  );
}

export default SettingsView;
