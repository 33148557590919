import Grid from '@material-ui/core/Grid';
import React from 'react';

import HomeViewStyles from '../assets/jss/components/HomeViewStyles';

// TODO remove legacy dashboard import and conditional dashboard rendering
//  when Mongo dashboard is done
import AppDashboard from '../components/Dashboard/Dashboard';
import AppLegacyDashboard from '../components/Dashboard/LegacyDashboard';
import DashboardPlaceholder from '../assets/img/dashboard_placeholder.svg';
import { makeStyles, Typography } from "@material-ui/core";

const USE_MONGO_DASHBOARD = process.env.REACT_APP_USE_MONGO_DASHBOARD === 'true';

// ui reflection of home view
export function HomeView() {
  const classes = useStyles();

  /**
   * Render mongo or legacy dashboard depends on USE_MONGO_DASHBOARD variable value
   */
  const dashboard = () => {
    if (USE_MONGO_DASHBOARD) {
      return <AppDashboard/>;
    }
    return <AppLegacyDashboard/>;
  };

  return (
    <Grid container className={classes.dashboardWrapper}>
      {/* <AppBreadcrumbs /> */}
      {/*{dashboard()}*/}

      <Grid className={classes.imgWrapper}>
        <DashboardPlaceholder/>
      </Grid>

      <Grid style={{ padding: '0 15px', textAlign: 'center' }}>
        <Typography variant={'subtitle1'} className={classes.title}>Welcome to Safe!</Typography>
        <Typography variant={'body2'} className={classes.description}>
          This is your admin console where you can configure and run
        </Typography>
        <Typography variant={'body2'} className={classes.description}>your custom healthcare solutions.</Typography>
      </Grid>

    </Grid>
  );
}

const useStyles = makeStyles(HomeViewStyles)

export default HomeView;
