import React from 'react';

// imports
import Alerts from '../components/Alerts/Alerts';
import AppBreadcrumbs from '../components/Shared/Breadcrumbs';

function AlertsView() {
  return (
    <div>
      <AppBreadcrumbs />
      <Alerts />
    </div>
  );
}

export default AlertsView;
