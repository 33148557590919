const QuestionnaireBuilderStyles = (theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.text.primary,
  },
});

export default QuestionnaireBuilderStyles;
