/* eslint-disable no-unused-vars */
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import PrivateRoute from '../components/Shared/PrivateRoute';
import { ResetPassword } from '../components/SignIn/ResetPassword';
import { SignIn } from '../components/SignIn/SignIn';
import { paths } from '../constants';
import AccountView from '../views/AccountView';
import AlertsView from '../views/AlertsView';
import AllUploadsView from '../views/AllUploadsView';
import AssetsCreateView from '../views/AssetsCreateView';
import AssetsEditView from '../views/AssetsEditView';
import ContactSupportView from '../views/ContactSupportView';
import ContentCreateView from '../views/ContentCreateView';
import AssetsIntroWidgetEditView from '../views/ContentEditView';
import ContentListView from '../views/ContentListView';
import DiagnosticReportView from '../views/DiagnosticReportView';
import EncounterDetailsView from '../views/EncounterDetailsView';
import EncountersView from '../views/EncountersView';
import AppHomeView from '../views/HomeView';
import ImporterView from '../views/ImporterView';
import ImportStatusView from '../views/ImportStatusView';
import MedicalCenterFormView from '../views/MedicalCenterFormView';
import MedicalCenterView from '../views/MedicalCenterView';
import MessageStandardizationCreateView from '../views/MessageStandardizationCreateView';
import MessageStandardizationEditView from '../views/MessageStandardizationEditView';
import MessageStandardizationView from '../views/MessageStandardizationView';
import NetworkView from '../views/NetworkView';
import AppNotFoundView from '../views/NotFoundView';
import OrdersView from '../views/OrdersView';
import ProfileView from '../views/ProfileView';
import RapidTestView from '../views/RapidTestView';
import RecordRequestDetailView from '../views/RecordRequestDetailView';
import RecordRequestsView from '../views/RecordRequestsView';
import ReportingView from '../views/ReportingView';
import SettingsView from '../views/SettingsView';
import TenantFormView from '../views/TenantFormView';
import UserCreateView from '../views/UserCreateView';
import UsersView from '../views/UsersView';
import VirtualConsultCreateView from '../views/VirtualConsultCreateView';
import VirtualConsultView from '../views/VirtualConsultView';

export const renderRoutes = (isLoggedIn) => {
  if (!isLoggedIn) {
    return (
      <Routes>
        <Route exact path={paths.signIn()} element={<SignIn />} />
        <Route exact path={paths.resetPassword()} element={<ResetPassword />} />
      </Routes>
    );
  }
  return (
    <Routes>
      <Route
        path={paths.start()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <AppHomeView />
          </PrivateRoute>
        }
      />
      <Route
        path={paths.dashboard()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <AppHomeView />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.users()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <UsersView />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.usersImport()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ImporterView />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.viewAllUploads()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <AllUploadsView />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.usersImportStatus()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ImportStatusView />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.userCreate()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <UserCreateView />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.profileDetails()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ProfileView type="userDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.profileDetailsAssessmentTab()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ProfileView type="userDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.profileDetailsTestingTab()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ProfileView type="userDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.profileDetailsScanTab()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ProfileView type="userDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.profileDetailsCovidHistTab()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ProfileView type="userDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.profileVaccineRecordsTab()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR']}>
            <ProfileView type="userDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.profileDetailsMessagesTab()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ProfileView type="userDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.profileDetailsVerificationTab()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ProfileView type="userDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.profileDetailsConsentsTab()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ProfileView type="userDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.profileDetailsDependentAccountsTab()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ProfileView type="userDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.profileSimulatorTab()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ProfileView type="simulator" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.profileDetailsSimulatorTab()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ProfileView type="simulator" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.profileDetailsClinicalTab()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ProfileView type="userDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.profileDetailsClinicalConditionTab()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ProfileView type="userDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.profileDetailsClinicalMedicationTab()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ProfileView type="userDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.profileDetailsClinicalPrescriptionTab()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ProfileView type="userDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.profileDetailsClinicalImmunizationTab()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ProfileView type="userDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.profileDetailsClinicalVitalTab()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ProfileView type="userDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.profileDetailsClinicalProcedureTab()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ProfileView type="userDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.profileDetailsClinicalCCDTab()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ProfileView type="userDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.profileDetailsClinicalCCDXMLTab()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ProfileView type="userDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.profileDetailsClinicalQuickConnectTab()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ProfileView type="userDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.profileDetailsClinicalEMRConnectTab()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ProfileView type="userDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.profileDetailsClinicalEprescribeTab()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ProfileView type="userDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.profileDetailsClinicalDosespotTab()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ProfileView type="userDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.orders()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR']}>
            <OrdersView />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.contentEdit()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <AssetsIntroWidgetEditView />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.contentCreate()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ContentCreateView />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.contentListByType()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ContentListView />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.contentList()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ContentListView />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.profile()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ProfileView type="profile" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.contactSupport()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ContactSupportView />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.profileAssessment()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ProfileView type="profile" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.profileTesting()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ProfileView type="profile" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.profileScans()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ProfileView type="profile" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.profileCovidHist()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ProfileView type="profile" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.profileMessages()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ProfileView type="profile" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.profileVerification()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <ProfileView type="profile" />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path={paths.alerts()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <AlertsView />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path={paths.account()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <AccountView />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path={paths.accountDetails()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <AccountView type="accountDetails" />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path={paths.accountTenants()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <AccountView type="tenantManagement" />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path={paths.accountPermissions()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <AccountView type="permissions" />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path={paths.accountTenantCreate()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <TenantFormView />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path={paths.settingsTemplatesTab()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <SettingsView type="templates" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.settingsEditorTab()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <SettingsView type="editor" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.settingsMessageStandardizationTab()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <MessageStandardizationView type="templates" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.settingsMessageStandardizationEditorTab()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <MessageStandardizationEditView />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.settingsMessageStandardizationCreate()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <MessageStandardizationCreateView />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.settingsVerbiageTab()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <SettingsView type="verbiage" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.settingsNetworkConfigTab()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <NetworkView />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.settingsNetworkConfigByTypeTab()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <NetworkView />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.settingsNetworkConfigByAssetsTypeTab()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <NetworkView />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.settingsAssetsEdit()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <AssetsEditView />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.settingsAssetsCreate()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <AssetsCreateView />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.medicalCenter()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <MedicalCenterView />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.medicalCenterOrderableTests()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <MedicalCenterView type="testOrderable" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.medicalCenterTestPanels()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <MedicalCenterView type="testPanels" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.medicalCenterVendors()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <MedicalCenterView type="vendors" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.medicalCenterCreateByType()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <MedicalCenterFormView />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.medicalCenterDetails()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <MedicalCenterFormView edit />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.virtualConsult()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <VirtualConsultView />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.virtualConsultReviewer()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <VirtualConsultView type="reviewer" edit />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.virtualConsultReviewerById()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <VirtualConsultView type="reviewer" edit />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.questionnaires()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <VirtualConsultView type="questionnaires" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.questionnairesCreate()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <VirtualConsultCreateView type="questionnaire" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.questionnairesCreateTab()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <VirtualConsultCreateView type="questionnaire" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.questionnairesDetails()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <VirtualConsultCreateView type="questionnaire" edit />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.questionnairesDetailsById()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <VirtualConsultCreateView type="questionnaire" edit />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path={paths.questionnairesDetailsById()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <VirtualConsultCreateView type="preview" edit />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.questions()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <VirtualConsultView type="questions" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.questionsCreate()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <VirtualConsultCreateView type="question" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.questionsDetailsById()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <VirtualConsultCreateView type="question" edit />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.outcomes()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <VirtualConsultView type="outcomes" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.outcomesCreate()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <VirtualConsultCreateView type="outcomes" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.outcomesDetailsById()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <VirtualConsultCreateView type="outcomes" edit />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.userSegments()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <VirtualConsultView type="userSegments" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.userSegmentsCreate()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <VirtualConsultCreateView type="userSegment" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.userSegmentsDetailsById()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <VirtualConsultCreateView type="userSegment" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.monitoring()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <VirtualConsultView type="monitoring" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.reports()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR']}>
            <ReportingView type="reports" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.customReport()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR']}>
            <ReportingView type="custom" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.diagnosticReport()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <DiagnosticReportView type="diagnosticReport" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.profileDetailsDiagnosticTestingReport()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <DiagnosticReportView type="diagnosticReport" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.rapidTest()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <RapidTestView type="rapidTestDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.profileDetailsRapidTestingReport()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <RapidTestView type="rapidTestDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.recordRequests()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <RecordRequestsView />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.recordRequestsDetails()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <RecordRequestDetailView type="userDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.recordRequestsDetailsClinical()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <RecordRequestDetailView type="userDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.recordRequestsDetailsClinicalCondition()}
        element={
          <PrivateRoute>
            <RecordRequestDetailView type="userDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.recordRequestsDetailsClinicalMedication()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <RecordRequestDetailView type="userDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.recordRequestsDetailsClinicalPrescription()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <RecordRequestDetailView type="userDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.recordRequestsDetailsClinicalImmunization()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <RecordRequestDetailView type="userDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.recordRequestsDetailsClinicalVital()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <RecordRequestDetailView type="userDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.recordRequestsDetailsClinicalProcedure()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <RecordRequestDetailView type="userDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.recordRequestsDetailsClinicalCCD()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <RecordRequestDetailView type="userDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.recordRequestsDetailsClinicalQuickConnect()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <RecordRequestDetailView type="userDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.recordRequestsDetailsClinicalEMRConnect()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <RecordRequestDetailView type="userDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.recordRequestsDetailsClinicalEprescribe()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <RecordRequestDetailView type="userDetails" />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.encounters()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <EncountersView />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path={paths.encountersDetails()}
        element={
          <PrivateRoute allowed={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}>
            <EncounterDetailsView />
          </PrivateRoute>
        }
      />
      <Route path={paths.notFound()} element={<AppNotFoundView />} />
    </Routes>
  );
};
